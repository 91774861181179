import { Badge } from "components/common/basic";
import { CheckListStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  viewer: "sender" | "receiver";
  status: CheckListStatusEnum;
  showStatus?: boolean;
  message?: string;
  badgeSize?: "sm" | "lg";
}

export default ({
  variant,
  viewer,
  status,
  message,
  badgeSize = "lg",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const isSender = viewer === "sender";

  const statusMessage =
    message ??
    t(
      `checkListStatusMessage_${isSender ? "sender" : "receiver"}.${snakeCaseToCamelCase(status)}`,
      snakeCaseToSeparated(status)
    );

  const badgeVariant = (() => {
    switch (status) {
      case CheckListStatusEnum.Completed:
        return "success";

      case CheckListStatusEnum.CompletedWithRemarks:
        return "warning";

      case CheckListStatusEnum.PendingCandidate:
      case CheckListStatusEnum.PendingConsent:
      case CheckListStatusEnum.PendingPlatform:
        return "pending";

      case CheckListStatusEnum.Cancelled:
      case CheckListStatusEnum.Refused:
      case CheckListStatusEnum.Unresponsive:
        return "error";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "font-bold", "text-deepBlue-900", {
          "text-xs": badgeSize === "sm",
        })}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};
