import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Input } from "components/common/basic";
import { useConfirmTfaPhoneNumberMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { useTranslation } from "translations";
import { TTailwindString, tw } from "utils/tw";

interface FormValues {
  code: string;
}

interface Props {
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  onSuccess: () => void;
  formStyles: TTailwindString;
}

export const ValidateSMSCodeForm = ({
  // phoneNumber: { countryCode, number },
  onSuccess,
  formStyles,
}: Props): JSX.Element => {
  const { confirmTfaPhoneNumber, isLoading } =
    useConfirmTfaPhoneNumberMutation();

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    code: Yup.string().required(
      t(
        "popup.twoFactorSetup.validateSMSCode.confirmationCode.error.required",
        "The code is required"
      )
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ code }: FormValues) =>
    confirmTfaPhoneNumber(code, onSuccess);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyles}>
      <Input
        id="code"
        {...register("code")}
        label={t("popup.twoFactorSetup.validateSMSCode.smsCode.label", "Code")}
        placeholder={t(
          "popup.twoFactorSetup.validateSMSCode.smsCode.placeholder",
          "Code"
        )}
        hideLabel
        errorMessage={errors.code?.message}
        className={tw("w-1/3")}
      />

      <Button
        id="two_factor_popup-submit_code"
        type="submit"
        variant="primary"
        size="md"
        disabled={isLoading}
        fullWidth
      >
        {t("popup.twoFactorSetup.validateSMSCode.button.submit", "Continue")}
      </Button>
    </form>
  );
};
