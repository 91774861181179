import { useEffect, useRef, useState } from "react";

import { useDebounce, useMenu, usePagination } from "hooks";

import { SearchWrapper, SingleSelectResults } from "../shared";
import { FESingleProps } from "../shared/types";

export default <T,>({
  id,
  itemKey,
  renderListItemLabel,
  onSearch,
  onSelect,
  onClear,
  buttonLabel,
  label,
  placeholder,
  hint,
  listHeader,
  footer,
  emptyState,
  minQueryLength = 0,
  perPage = 5,
  errorMessage,
  debounceTime = 750,
  reloadOnOpen = false,
  showPagination = true,
}: Omit<FESingleProps<T>, "isMultiSelect">): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, debounceTime);

  const { page, pages, pageItems, goToPage, resetItems } = usePagination({
    items: [] as T[],
    perPage,
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useMenu(menuRef);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (debouncedValue.length < minQueryLength) return;

    onSearch(debouncedValue).then(resetItems);
  }, [debouncedValue]);

  useEffect(() => {
    if (!reloadOnOpen || !isOpen) return;

    onSearch(searchInput).then(resetItems);
  }, [isOpen]);

  return (
    <SearchWrapper
      menuRef={menuRef}
      id={id}
      defaultSearchInput={searchInput}
      onSearch={setSearchInput}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      buttonLabel={
        typeof buttonLabel === "function" ? buttonLabel(isOpen) : buttonLabel
      }
      label={label}
      placeholder={placeholder}
      hint={hint}
      isLoading={false}
      errorMessage={errorMessage}
    >
      <SingleSelectResults<T>
        listHeader={listHeader}
        itemKey={itemKey}
        items={pageItems}
        renderListItemLabel={renderListItemLabel}
        onSelect={onSelect}
        onClear={onClear}
        onClose={() => setIsOpen(false)}
        page={page}
        pages={pages}
        setPage={goToPage}
        emptyState={emptyState}
        footer={footer}
        showPagination={showPagination}
      />
    </SearchWrapper>
  );
};
