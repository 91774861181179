import { IconType } from "react-icons";
import { HiOutlineIdentification } from "react-icons/hi";

import { ActionID } from "components/common/basic";
import { MmShield } from "components/common/basic/Icon/icons";
import { useTranslation } from "translations";
import { RoutesByModule } from "utils";

interface LinkProps {
  actionId: ActionID;
  routeName: RoutesByModule["account"];
  Icon: IconType;
  label: string;
  helpText: string;
}

export default (): LinkProps[] => {
  const { t } = useTranslation("account");

  const links: LinkProps[] = [
    {
      actionId: "accountSettings-go_to_accountInfo",
      routeName: "SETTINGS_INFO",
      Icon: HiOutlineIdentification,
      label: t("accountSettings_ind.link.accountInfo.label", "Account info"),
      helpText: t(
        "accountSettings_ind.link.orgInfo.helpText",
        "Provide personal details and how we can reach you"
      ),
    },
    {
      actionId: "accountSettings-go_to_authSettings",
      routeName: "SETTINGS_AUTH",
      Icon: MmShield,
      label: t(
        "accountSettings_ind.link.authentication.label",
        "Login & security"
      ),
      helpText: t(
        "accountSettings_ind.link.authentication.helpText",
        "Update your password and secure your account"
      ),
    },
  ];

  return links;
};
