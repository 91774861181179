import { useContext, useEffect, useRef, useState } from "react";
import {
  HiMenu,
  HiOutlineFingerPrint,
  HiOutlinePencilAlt,
  HiOutlineUserCircle,
  HiPlus,
  HiX,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { AccountTypeEnum } from "graphql/types";
import { useMenu } from "hooks";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { symbolDark } from "utils/brand";
import { tw } from "utils/tw";

import { MenuList, TopMenuList } from "./common";
import useSideMenuItems from "./common/useSideMenuItems";

enum ActiveMenu {
  Side = "SIDE",
  Creation = "CREATE",
  Top = "TOP",
}

export default (): JSX.Element => {
  const [activeMenu, setActiveMenu] = useState<ActiveMenu>();
  const toggleMenu = (menu: ActiveMenu) => () =>
    setActiveMenu(activeMenu === menu ? undefined : menu);

  const createMenuRef = useRef<HTMLDivElement>(null);
  const [_isShowingCreateMenu, setIsShowingCreateMenu] = useMenu(createMenuRef);

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const sideMenuItems = useSideMenuItems({ session });

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  // Close menus when changing page
  useEffect(() => {
    setActiveMenu(undefined);
  }, [pathname]);

  // Set creation menu based on active menu
  useEffect(() => {
    if (activeMenu === ActiveMenu.Creation) setIsShowingCreateMenu(true);

    setIsShowingCreateMenu(false);
  }, [activeMenu]);

  const tabBarStyles = tw(
    "fixed",
    "z-40",
    "bottom-0",
    "border-t-2",
    "border-silver",
    "bg-white",
    "w-screen",
    "flex"
  );

  const linkBase = tw(
    "w-full",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "space-y-1",
    "pt-2",
    "pb-4",
    "text-xs",
    "font-bold"
  );
  const linkStyles = tw(
    linkBase,
    "border-transparent",
    "text-gray-500",
    "hover:border-gray-300",
    "hover:text-gray-700"
  );
  const activeLinkStyles = tw(linkBase, "border-blue-900", "text-blue-900");

  const createButtonStyles = tw(
    "w-12",
    "h-12",
    "rounded-full",
    "flex",
    "justify-center",
    "items-center",

    "border",
    "border-transparent",

    "shadow-sm",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900",
    "bg-primary",
    "text-primary-contrast",
    "hover:bg-primary-hover"
  );

  const dropdownMenuStyles = tw(
    "absolute",
    "bottom-full",
    "mb-6",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  const fullscreenMenuStyles = tw(
    "fixed",
    "z-30",
    "top-0",
    "left-0",
    "bg-white",
    "h-screen",
    "w-screen",
    "pb-16",
    "overflow-auto"
  );

  const canCreateCheck =
    session?.account.features.checks &&
    session.account.accountType === AccountTypeEnum.Team;
  const hasCreationFeature = Boolean(
    session?.account.features.invoices ||
      session?.account.features.eSignatures ||
      canCreateCheck
  );

  return (
    <>
      <nav className={tabBarStyles}>
        <div className={tw("relative", "w-1/3")}>
          <button
            aria-label="menu"
            aria-expanded={activeMenu === ActiveMenu.Side}
            className={
              activeMenu === ActiveMenu.Side ? activeLinkStyles : linkStyles
            }
            onClick={toggleMenu(ActiveMenu.Side)}
          >
            {session?.account.accountType === AccountTypeEnum.Personal ? (
              <HiOutlineUserCircle size="24px" />
            ) : (
              <img
                src={
                  session?.account.symbol
                    ? `${process.env.REACT_APP_API_URL}${session.account.symbol.downloadPath}`
                    : symbolDark
                }
                alt="avatar"
                className={tw("rounded-full", "w-6", "h-6", "object-cover")}
              />
            )}

            <span className={tw("px-4", "max-w-full", "truncate")}>
              {session?.account.name ?? session?.account.email}
            </span>
          </button>
        </div>

        <div
          ref={createMenuRef}
          className={tw("relative", "w-1/3", "flex", "justify-center")}
        >
          {hasCreationFeature && (
            <div className={tw("absolute", "bottom-0")}>
              <div className={linkStyles}>
                <button
                  aria-label="create menu"
                  aria-expanded={activeMenu === ActiveMenu.Creation}
                  className={createButtonStyles}
                  onClick={toggleMenu(ActiveMenu.Creation)}
                >
                  <HiPlus size="24px" />
                </button>

                {t("layout.appTabBar.button.create", "Create")}
              </div>
            </div>
          )}

          {activeMenu === ActiveMenu.Creation && (
            <nav className={dropdownMenuStyles}>
              <MenuList
                menuItems={[
                  ...(session?.account.features.eSignatures
                    ? [
                        {
                          id: "app_tab_bar-create_esignature" as const,
                          Icon: HiOutlinePencilAlt,
                          label: t(
                            "layout.appTabBar.link.createESignature",
                            "New eSignature"
                          ),
                          to: getRoutePath({
                            module: "signmore",
                            routeName:
                              session.account.accountType ===
                              AccountTypeEnum.Team
                                ? "DOCUMENTS"
                                : "CREATE_DOCUMENT_WIZARD",
                          }),
                        },
                      ]
                    : []),
                  ...(canCreateCheck
                    ? [
                        {
                          id: "app_tab_bar-create_check" as const,
                          Icon: HiOutlineFingerPrint,
                          label: t(
                            "layout.appTabBar.link.createCheck",
                            "New check"
                          ),
                          to: getRoutePath({
                            module: "checkmore",
                            routeName: "CHECK_TYPE_SELECTION",
                          }),
                        },
                      ]
                    : []),
                ]}
              />
            </nav>
          )}
        </div>

        <div className={tw("relative", "w-1/3")}>
          <button
            aria-label="menu"
            aria-expanded={activeMenu === ActiveMenu.Top}
            className={
              activeMenu === ActiveMenu.Top ? activeLinkStyles : linkStyles
            }
            onClick={toggleMenu(ActiveMenu.Top)}
          >
            {activeMenu === ActiveMenu.Top ? (
              <HiX size="24px" />
            ) : (
              <HiMenu size="24px" />
            )}

            {t("layout.appTabBar.button.topMenu", "Menu")}
          </button>
        </div>
      </nav>

      {activeMenu === ActiveMenu.Side && (
        <nav className={fullscreenMenuStyles}>
          <h1 className={tw("p-5", "text-2xl", "font-extrabold")}>
            {t("layout.appTabBar.button.sideMenu", "Spaces")}
          </h1>

          <MenuList menuItems={sideMenuItems} />
        </nav>
      )}

      {activeMenu === ActiveMenu.Top && (
        <nav className={fullscreenMenuStyles}>
          <h1 className={tw("p-5", "text-2xl", "font-extrabold")}>
            {t("layout.appTabBar.button.topMenu", "Menu")}
          </h1>

          <TopMenuList showLanguageSelect />

          {/* TODO: Show workspace in mobile menu */}
          {/* <Workspace /> */}
        </nav>
      )}
    </>
  );
};
