import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Input } from "components/common/basic";
import {
  useResendTfaCodeMutation,
  useValidateSessionTfaMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { Trans, useTranslation } from "translations";
import { useDefaultRoutePath } from "utils";
import { tw } from "utils/tw";

import { PopupWrapper } from "../PopupWrapper";

interface FormValues {
  code: string;
}

interface Props {
  sessionToken: string;
  deepLink?: string;
  onClose: () => void;
  onSuccessfulValidation: () => void;
}

export default ({
  sessionToken,
  deepLink,
  onClose,
  onSuccessfulValidation,
}: Props): JSX.Element => {
  const { validateSessionTfa, isLoading: isValidating } =
    useValidateSessionTfaMutation();
  const { resendTfaCode, isLoading: isResending } = useResendTfaCodeMutation();
  const isLoading = isValidating || isResending;

  const { t } = useTranslation("common");
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();
  const defaultRoutePath = useDefaultRoutePath();

  const validationSchema = Yup.object({
    code: Yup.string().required(
      t(
        "popup.twoFactorAuthentication.smsCode.error.required",
        "The code is required"
      )
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = async ({ code }: FormValues) => {
    validateSessionTfa({ sessionToken, tfaCode: code }, (token) => {
      login(token);

      if (deepLink) navigate(deepLink.split(window.origin)[1]);
      else navigate(defaultRoutePath);

      onSuccessfulValidation();
    });
  };

  return (
    <PopupWrapper
      onClose={onClose}
      isClosable={!window.location.pathname.includes("magic-link")}
      hasPrevious={false}
      title={t(
        "popup.twoFactorAuthentication.heading",
        "Phone number verification"
      )}
      variant="popup"
    >
      <div className={tw("space-y-6")}>
        <p>
          {t(
            "popup.twoFactorAuthentication.paragraph",
            "Enter the 6-digit code we just texted to your registered phone number"
          )}
        </p>

        <p className={tw("text-sm", "text-deepBlue-900")}>
          <Trans
            ns="common"
            i18nKey="popup.twoFactorAuthentication.resendSMS"
            defaults="Didn't get a text? <0>Send again</0>."
            components={[
              <button
                id="two_factor_popup-send_sms_code"
                className={tw(
                  "underline",
                  "text-sm",
                  "font-semibold",
                  "text-deepBlue-900"
                )}
                onClick={() => resendTfaCode({ sessionToken })}
                disabled={isLoading}
              />,
            ]}
          />
        </p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={tw("flex", "flex-col", "space-y-6")}
        >
          <Input
            id="code"
            {...register("code")}
            label={t("popup.twoFactorAuthentication.smsCode.label", "Code")}
            placeholder={t(
              "popup.twoFactorAuthentication.smsCode.placeholder",
              "Code"
            )}
            hideLabel
            errorMessage={errors.code?.message}
            className={tw("mx-auto", "w-1/3")}
          />

          <Button
            id="two_factor_popup-submit_code"
            type="submit"
            variant="primary"
            size="md"
            disabled={isLoading}
            fullWidth
          >
            {t("popup.twoFactorAuthentication.button.submit", "Continue")}
          </Button>
        </form>
      </div>
    </PopupWrapper>
  );
};
