export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  EmailField: { input: any; output: any; }
};

export type Account = Node & {
  __typename?: 'Account';
  accountType: AccountTypeEnum;
  address?: Maybe<Address>;
  canManage: Scalars['Boolean']['output'];
  canManageDepartments: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['String']['output']>;
  counts?: Maybe<Counts>;
  defaultIdentity?: Maybe<Identity>;
  deletionScheduledOn?: Maybe<Scalars['String']['output']>;
  departmentMemberships: Array<DepartmentMembership>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  features: LegacyFeatures;
  hasWhistleblowingAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identities?: Maybe<Array<Identity>>;
  industrySector?: Maybe<Scalars['String']['output']>;
  isWbHandler: Scalars['Boolean']['output'];
  language: LanguageEnum;
  limits?: Maybe<FeatureCounts>;
  logo?: Maybe<Attachment>;
  monogramPath?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nonProfit: Scalars['Boolean']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Account>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policyDocuments: Array<Attachment>;
  pricePlan?: Maybe<PricePlanEnum>;
  primary: Scalars['Boolean']['output'];
  primaryUser?: Maybe<User>;
  registrationTier?: Maybe<Tier>;
  role?: Maybe<TeamRoleEnum>;
  routineGuidelines: Array<Attachment>;
  slug: Scalars['String']['output'];
  subscription?: Maybe<Subscription>;
  symbol?: Maybe<Attachment>;
  symbolDisplayPath?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<AccountTagEnum>;
  tfaPendingPhoneCode?: Maybe<Scalars['String']['output']>;
  tfaPendingPhoneNumber?: Maybe<Scalars['String']['output']>;
  tfaPhoneCode?: Maybe<Scalars['String']['output']>;
  tfaPhoneNumber?: Maybe<Scalars['String']['output']>;
  theme: AccountTheme;
  uiFlags: UiFlags;
  usage?: Maybe<FeatureCounts>;
  wbSettings: WbSettings;
  wbSlug: Scalars['String']['output'];
  webhookUrl?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerJpgDownloadPath?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerPdfDownloadPath?: Maybe<Scalars['String']['output']>;
  whistleblowingStickerPngDownloadPath?: Maybe<Scalars['String']['output']>;
};

export type AccountPagination = {
  __typename?: 'AccountPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Account>;
  total: Scalars['Int']['output'];
};

export enum AccountTagEnum {
  Generic = 'GENERIC',
  HealthServiceYouth = 'HEALTH_SERVICE_YOUTH',
  Kindergarten = 'KINDERGARTEN',
  KindergartenAndSchool = 'KINDERGARTEN_AND_SCHOOL',
  LanguageService = 'LANGUAGE_SERVICE',
  MarchingBand = 'MARCHING_BAND',
  Religious = 'RELIGIOUS',
  Sports = 'SPORTS',
  Volunteer = 'VOLUNTEER'
}

export type AccountTheme = {
  __typename?: 'AccountTheme';
  font?: Maybe<AccountThemeFont>;
  primary?: Maybe<Scalars['String']['output']>;
  primaryContrast?: Maybe<Scalars['String']['output']>;
  primaryHover?: Maybe<Scalars['String']['output']>;
};

export type AccountThemeAttributes = {
  font?: InputMaybe<AccountThemeFontAttributes>;
  primary?: InputMaybe<Scalars['String']['input']>;
  primaryContrast?: InputMaybe<Scalars['String']['input']>;
  primaryHover?: InputMaybe<Scalars['String']['input']>;
};

export type AccountThemeFont = {
  __typename?: 'AccountThemeFont';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AccountThemeFontAttributes = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum AccountTypeEnum {
  Personal = 'PERSONAL',
  Team = 'TEAM'
}

export enum ActorEnum {
  Administrator = 'ADMINISTRATOR',
  Anonymous = 'ANONYMOUS',
  System = 'SYSTEM',
  User = 'USER'
}

/** Autogenerated input type of AddAccountMember */
export type AddAccountMemberInput = {
  attributes: AddMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddAccountMember. */
export type AddAccountMemberPayload = {
  __typename?: 'AddAccountMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  member?: Maybe<Account>;
};

export type AddMemberAttributes = {
  departmentMemberships: Array<DepartmentMembershipAttributes>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role: TeamRoleEnum;
};

export type AddTfaNumberAttributes = {
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

/** Autogenerated input type of AddTfaPhoneNumber */
export type AddTfaPhoneNumberInput = {
  attributes: AddTfaNumberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AddTfaPhoneNumber. */
export type AddTfaPhoneNumberPayload = {
  __typename?: 'AddTfaPhoneNumberPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type AddressAttributes = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  county?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode: Scalars['String']['input'];
};

/** Autogenerated input type of ArchiveCheckLists */
export type ArchiveCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchiveCheckLists. */
export type ArchiveCheckListsPayload = {
  __typename?: 'ArchiveCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ArchiveDocuments */
export type ArchiveDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchiveDocuments. */
export type ArchiveDocumentsPayload = {
  __typename?: 'ArchiveDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of ArchivePeople */
export type ArchivePeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of ArchivePeople. */
export type ArchivePeoplePayload = {
  __typename?: 'ArchivePeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

export type Attachment = {
  __typename?: 'Attachment';
  contentType?: Maybe<Scalars['String']['output']>;
  downloadPath: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  thumbPath?: Maybe<Scalars['String']['output']>;
};

export type Base64AttachmentAttributes = {
  data: Scalars['String']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CancelAccountDeletion */
export type CancelAccountDeletionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelAccountDeletion. */
export type CancelAccountDeletionPayload = {
  __typename?: 'CancelAccountDeletionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CancelCheckLists */
export type CancelCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of CancelCheckLists. */
export type CancelCheckListsPayload = {
  __typename?: 'CancelCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CancelDocumentAttributes = {
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CancelDocuments */
export type CancelDocumentsInput = {
  attributes: CancelDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of CancelDocuments. */
export type CancelDocumentsPayload = {
  __typename?: 'CancelDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelSubscription. */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type Check = Node & {
  __typename?: 'Check';
  checkList: CheckList;
  criminalRecordAttributes?: Maybe<CriminalRecordCheck>;
  eLearningAttributes?: Maybe<ELearningCheck>;
  educationAttributes?: Maybe<EducationCheck>;
  events?: Maybe<Array<Event>>;
  id: Scalars['ID']['output'];
  identityAttributes?: Maybe<IdentityCheck>;
  informationAttributes?: Maybe<InformationCheck>;
  openSourceSearchAttributes?: Maybe<OpenSourceSearchCheck>;
  personalNumberAttributes?: Maybe<PersonalNumberCheck>;
  refuseReason?: Maybe<Scalars['String']['output']>;
  relatedCountriesAttributes?: Maybe<RelatedCountriesCheck>;
  specificSourceSearchAttributes?: Maybe<SpecificSourceSearchCheck>;
  status: CheckStatusEnum;
  type: CheckTypeEnum;
  workExperienceAttributes?: Maybe<WorkExperienceCheck>;
};

export type CheckAttributes = {
  criminalRecordAttributes?: InputMaybe<CriminalRecordCheckAttributes>;
  educationAttributes?: InputMaybe<EducationCheckAttributes>;
  identityAttributes?: InputMaybe<IdentityCheckAttributes>;
  openSourceSearchAttributes?: InputMaybe<OpenSourceSearchCheckAttributes>;
  personalNumberAttributes?: InputMaybe<PersonalNumberCheckAttributes>;
  relatedCountriesAttributes?: InputMaybe<RelatedCountriesCheckAttributes>;
  specificSourceSearchAttributes?: InputMaybe<SpecificSourceSearchCheckAttributes>;
  workExperienceAttributes?: InputMaybe<WorkExperienceCheckAttributes>;
};

export enum CheckFileDocumentTypeEnum {
  CrcAlreadyIssued = 'CRC_ALREADY_ISSUED',
  ImageNotQualified = 'IMAGE_NOT_QUALIFIED',
  InformationRequired = 'INFORMATION_REQUIRED',
  NotCrc = 'NOT_CRC',
  ProbablyCrc = 'PROBABLY_CRC',
  PurposeLetter = 'PURPOSE_LETTER',
  UnprocessableFile = 'UNPROCESSABLE_FILE',
  ValidCrc = 'VALID_CRC'
}

export type CheckList = Node & {
  __typename?: 'CheckList';
  archivedAt?: Maybe<Scalars['String']['output']>;
  checkRequest: CheckRequest;
  checks: Array<Check>;
  criminalRecordAttributes?: Maybe<CriminalRecordCheckList>;
  department?: Maybe<Department>;
  departments: Array<Department>;
  email: Scalars['EmailField']['output'];
  events?: Maybe<Array<CheckListEvent>>;
  id: Scalars['ID']['output'];
  lastContactedAt?: Maybe<Scalars['String']['output']>;
  name: Name;
  pdfReportPath?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  purposeLetterPath?: Maybe<Scalars['String']['output']>;
  recentlyDoneThisCheck: Scalars['Boolean']['output'];
  refuseReason?: Maybe<Scalars['String']['output']>;
  requestedAt: Scalars['String']['output'];
  status: CheckListStatusEnum;
};

export type CheckListEvent = {
  __typename?: 'CheckListEvent';
  actor: ActorEnum;
  actorName?: Maybe<Scalars['String']['output']>;
  eventType: CheckListEventTypeEnum;
  note?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['String']['output'];
  status: CheckListStatusEnum;
};

export enum CheckListEventTypeEnum {
  CheckListAutoReminderSent = 'CHECK_LIST_AUTO_REMINDER_SENT',
  CheckListCancelled = 'CHECK_LIST_CANCELLED',
  CheckListCompleted = 'CHECK_LIST_COMPLETED',
  CheckListConsented = 'CHECK_LIST_CONSENTED',
  CheckListFinalReminderSent = 'CHECK_LIST_FINAL_REMINDER_SENT',
  CheckListPurposeLetterGenerated = 'CHECK_LIST_PURPOSE_LETTER_GENERATED',
  CheckListRefused = 'CHECK_LIST_REFUSED',
  CheckListReminderSent = 'CHECK_LIST_REMINDER_SENT',
  CheckListRequested = 'CHECK_LIST_REQUESTED'
}

export type CheckListPagination = {
  __typename?: 'CheckListPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<CheckList>;
  total: Scalars['Int']['output'];
};

export enum CheckListStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  CompletedWithRemarks = 'COMPLETED_WITH_REMARKS',
  PendingCandidate = 'PENDING_CANDIDATE',
  PendingConsent = 'PENDING_CONSENT',
  PendingPlatform = 'PENDING_PLATFORM',
  Refused = 'REFUSED',
  Unresponsive = 'UNRESPONSIVE'
}

export type CheckListsFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CheckListStatusEnum>;
};

export enum CheckListsSortByEnum {
  LastContactedAtAsc = 'LAST_CONTACTED_AT_ASC',
  LastContactedAtDesc = 'LAST_CONTACTED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type CheckRequest = Node & {
  __typename?: 'CheckRequest';
  account: Account;
  checkLists: Array<CheckList>;
  checkTypes: Array<CheckTypeEnum>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  contactRole?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Array<IdentityCountryCodeEnum>>;
  crcPurpose?: Maybe<CrcPurpose>;
  eLearningCourse: ELearningCourseEnum;
  educationRequirements?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  id: Scalars['ID']['output'];
  includeCheckedCandidates?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  previewPurposeLetterPath?: Maybe<Scalars['String']['output']>;
  status: CheckRequestStatusEnum;
  type: CheckRequestTypeEnum;
};

export type CheckRequestAttributes = {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  contactRole?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<IdentityCountryCodeEnum>>;
  crcPurposeId?: InputMaybe<Scalars['ID']['input']>;
  eLearningCourse?: InputMaybe<ELearningCourseEnum>;
  educationRequirements?: InputMaybe<Scalars['String']['input']>;
  includeCheckedCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CheckRequestStatusEnum {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export enum CheckRequestTypeEnum {
  Basic = 'BASIC',
  BasicPlus = 'BASIC_PLUS',
  Comprehensive = 'COMPREHENSIVE',
  CriminalRecord = 'CRIMINAL_RECORD',
  Enhanced = 'ENHANCED',
  Identity = 'IDENTITY',
  Information = 'INFORMATION'
}

export enum CheckStatusEnum {
  Completed = 'COMPLETED',
  CompletedWithRemarks = 'COMPLETED_WITH_REMARKS',
  PendingAdmin = 'PENDING_ADMIN',
  PendingCandidate = 'PENDING_CANDIDATE',
  PendingExternal = 'PENDING_EXTERNAL'
}

export enum CheckTypeEnum {
  Address = 'ADDRESS',
  Bankruptcy = 'BANKRUPTCY',
  BankId = 'BANK_ID',
  BusinessInterests = 'BUSINESS_INTERESTS',
  Certificates = 'CERTIFICATES',
  CriminalRecord = 'CRIMINAL_RECORD',
  Education = 'EDUCATION',
  ELearning = 'E_LEARNING',
  GlobalIdentity = 'GLOBAL_IDENTITY',
  Identity = 'IDENTITY',
  Information = 'INFORMATION',
  LegalCaseRecords = 'LEGAL_CASE_RECORDS',
  OpenSourceSearch = 'OPEN_SOURCE_SEARCH',
  PersonalCredit = 'PERSONAL_CREDIT',
  PersonalNumber = 'PERSONAL_NUMBER',
  RelatedCountries = 'RELATED_COUNTRIES',
  SpecificSourceSearch = 'SPECIFIC_SOURCE_SEARCH',
  WorkExperience = 'WORK_EXPERIENCE',
  WorkPermit = 'WORK_PERMIT'
}

/** Autogenerated input type of CloseWbReport */
export type CloseWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CloseWbReport. */
export type CloseWbReportPayload = {
  __typename?: 'CloseWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsBankruptcy?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgNumber: Scalars['String']['output'];
  underForcedLiquidation?: Maybe<Scalars['Boolean']['output']>;
  underLiquidation?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmTfaNumberAttributes = {
  code: Scalars['String']['input'];
};

/** Autogenerated input type of ConfirmTfaPhoneNumber */
export type ConfirmTfaPhoneNumberInput = {
  attributes: ConfirmTfaNumberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ConfirmTfaPhoneNumber. */
export type ConfirmTfaPhoneNumberPayload = {
  __typename?: 'ConfirmTfaPhoneNumberPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ConsentCheckList */
export type ConsentCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ConsentCheckList. */
export type ConsentCheckListPayload = {
  __typename?: 'ConsentCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export enum CountryCodeEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type Counts = {
  __typename?: 'Counts';
  archivedAllDocuments: Scalars['Int']['output'];
  archivedCancelledDocuments: Scalars['Int']['output'];
  archivedDraftDocuments: Scalars['Int']['output'];
  archivedPendingDocuments: Scalars['Int']['output'];
  archivedRefusedDocuments: Scalars['Int']['output'];
  archivedSignedDocuments: Scalars['Int']['output'];
  checkLists: Scalars['Int']['output'];
  departments: Scalars['Int']['output'];
  members: Scalars['Int']['output'];
  people: Scalars['Int']['output'];
  receivedAllDocuments: Scalars['Int']['output'];
  receivedCancelledDocuments: Scalars['Int']['output'];
  receivedDraftDocuments: Scalars['Int']['output'];
  receivedPendingDocuments: Scalars['Int']['output'];
  receivedRefusedDocuments: Scalars['Int']['output'];
  receivedSignedDocuments: Scalars['Int']['output'];
  sentAllDocuments: Scalars['Int']['output'];
  sentCancelledDocuments: Scalars['Int']['output'];
  sentDraftDocuments: Scalars['Int']['output'];
  sentPendingDocuments: Scalars['Int']['output'];
  sentRefusedDocuments: Scalars['Int']['output'];
  sentSignedDocuments: Scalars['Int']['output'];
  wbReports: Scalars['Int']['output'];
};

export type CrcPurpose = Node & {
  __typename?: 'CrcPurpose';
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  legalBasis: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
  tags: Array<AccountTagEnum>;
};

export type CrcPurposePagination = {
  __typename?: 'CrcPurposePagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<CrcPurpose>;
  total: Scalars['Int']['output'];
};

export type CreateAccountAttributes = {
  name: Scalars['String']['input'];
};

export type CreateCheckListAttributes = {
  criminalRecordAttributes?: InputMaybe<CriminalRecordCheckListAttributes>;
  departmentId: Scalars['ID']['input'];
  email: Scalars['EmailField']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateCheckLists */
export type CreateCheckListsInput = {
  attributes: Array<CreateCheckListAttributes>;
  checkRequestId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCheckLists. */
export type CreateCheckListsPayload = {
  __typename?: 'CreateCheckListsPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateCheckRequest */
export type CreateCheckRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: CheckRequestTypeEnum;
};

/** Autogenerated return type of CreateCheckRequest. */
export type CreateCheckRequestPayload = {
  __typename?: 'CreateCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDepartment */
export type CreateDepartmentInput = {
  attributes: DepartmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDepartment. */
export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentFile */
export type CreateDocumentFileInput = {
  attributes: DocumentFileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateDocumentFile. */
export type CreateDocumentFilePayload = {
  __typename?: 'CreateDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentFile?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocument */
export type CreateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateDocument. */
export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type CreatePaymentMethodAttributes = {
  address: AddressAttributes;
  contactName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  orderReference: Scalars['String']['input'];
  organizationNumber: Scalars['String']['input'];
};

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
  attributes: CreatePaymentMethodAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of CreatePolicyDocument */
export type CreatePolicyDocumentInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreatePolicyDocument. */
export type CreatePolicyDocumentPayload = {
  __typename?: 'CreatePolicyDocumentPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateRoutineGuideline */
export type CreateRoutineGuidelineInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateRoutineGuideline. */
export type CreateRoutineGuidelinePayload = {
  __typename?: 'CreateRoutineGuidelinePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated input type of CreateSessionOtp */
export type CreateSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
};

/** Autogenerated return type of CreateSessionOtp. */
export type CreateSessionOtpPayload = {
  __typename?: 'CreateSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated return type of CreateSession. */
export type CreateSessionPayload = {
  __typename?: 'CreateSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateSignatures */
export type CreateSignaturesInput = {
  attributes: Array<SignatureAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSignatures. */
export type CreateSignaturesPayload = {
  __typename?: 'CreateSignaturesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateStripeCard */
export type CreateStripeCardInput = {
  attributes: StripeCardAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateStripeCard. */
export type CreateStripeCardPayload = {
  __typename?: 'CreateStripeCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  __typename?: 'CreateStripeSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  setupIntent?: Maybe<StripeSetupIntent>;
};

/** Autogenerated input type of CreateTeamAccount */
export type CreateTeamAccountInput = {
  attributes: CreateAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateTeamAccount. */
export type CreateTeamAccountPayload = {
  __typename?: 'CreateTeamAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateVeriffSession */
export type CreateVeriffSessionInput = {
  checkListId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateVeriffSession. */
export type CreateVeriffSessionPayload = {
  __typename?: 'CreateVeriffSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  veriffSession?: Maybe<VeriffSession>;
};

/** Autogenerated input type of CreateWbHandler */
export type CreateWbHandlerInput = {
  accountId: Scalars['ID']['input'];
  attributes: WbHandlerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateWbHandler. */
export type CreateWbHandlerPayload = {
  __typename?: 'CreateWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of CreateWbMessage */
export type CreateWbMessageInput = {
  attributes: WbMessageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  wbReportId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateWbMessage. */
export type CreateWbMessagePayload = {
  __typename?: 'CreateWbMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of CreateWbReportFile */
export type CreateWbReportFileInput = {
  attributes: Base64AttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateWbReportFile. */
export type CreateWbReportFilePayload = {
  __typename?: 'CreateWbReportFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

/** Autogenerated input type of CreateWbReport */
export type CreateWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: WbReportTypeEnum;
  wbSlug: Scalars['String']['input'];
};

/** Autogenerated return type of CreateWbReport. */
export type CreateWbReportPayload = {
  __typename?: 'CreateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

/** Autogenerated input type of CriiptoVerify */
export type CriiptoVerifyInput = {
  checkListId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

/** Autogenerated return type of CriiptoVerify. */
export type CriiptoVerifyPayload = {
  __typename?: 'CriiptoVerifyPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type CriminalRecordCheck = {
  __typename?: 'CriminalRecordCheck';
  files?: Maybe<Array<Attachment>>;
};

export type CriminalRecordCheckAttributes = {
  files: Array<Base64AttachmentAttributes>;
};

export type CriminalRecordCheckList = {
  __typename?: 'CriminalRecordCheckList';
  alreadyEmployed: Scalars['Boolean']['output'];
  employedOn: Scalars['String']['output'];
  jobDescription: Scalars['String']['output'];
  jobRole: Scalars['String']['output'];
  temporaryJob: Scalars['Boolean']['output'];
};

export type CriminalRecordCheckListAttributes = {
  alreadyEmployed: Scalars['Boolean']['input'];
  employedOn: Scalars['String']['input'];
  jobDescription: Scalars['String']['input'];
  jobRole: Scalars['String']['input'];
  temporaryJob: Scalars['Boolean']['input'];
};

/** Autogenerated input type of DeleteAccount */
export type DeleteAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAccount. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteIdentity */
export type DeleteIdentityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteIdentity. */
export type DeleteIdentityPayload = {
  __typename?: 'DeleteIdentityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  identity?: Maybe<Identity>;
};

/** Autogenerated input type of DeleteWbReport */
export type DeleteWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteWbReport. */
export type DeleteWbReportPayload = {
  __typename?: 'DeleteWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

export type Department = Node & {
  __typename?: 'Department';
  canAccess: Scalars['Boolean']['output'];
  canManage: Scalars['Boolean']['output'];
  default: Scalars['Boolean']['output'];
  departmentMemberships: Array<DepartmentMembership>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DepartmentAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DepartmentMembership = Node & {
  __typename?: 'DepartmentMembership';
  account: Account;
  criminalRecordAccess: Scalars['Boolean']['output'];
  department: Department;
  id: Scalars['ID']['output'];
  identityAccess: Scalars['Boolean']['output'];
  role: DepartmentRoleEnum;
};

export type DepartmentMembershipAttributes = {
  criminalRecordAccess?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId: Scalars['ID']['input'];
  identityAccess?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<DepartmentRoleEnum>;
};

export type DepartmentPagination = {
  __typename?: 'DepartmentPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Department>;
  total: Scalars['Int']['output'];
};

export enum DepartmentRoleEnum {
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export type DepartmentsFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DestroyAccountLogo */
export type DestroyAccountLogoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroyAccountLogo. */
export type DestroyAccountLogoPayload = {
  __typename?: 'DestroyAccountLogoPayload';
  account: Account;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyAccountSymbol */
export type DestroyAccountSymbolInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personal?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of DestroyAccountSymbol. */
export type DestroyAccountSymbolPayload = {
  __typename?: 'DestroyAccountSymbolPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyCheckList */
export type DestroyCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyCheckList. */
export type DestroyCheckListPayload = {
  __typename?: 'DestroyCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyCheckRequestFile */
export type DestroyCheckRequestFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyCheckRequestFile. */
export type DestroyCheckRequestFilePayload = {
  __typename?: 'DestroyCheckRequestFilePayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDepartment */
export type DestroyDepartmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyDepartment. */
export type DestroyDepartmentPayload = {
  __typename?: 'DestroyDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocumentFile */
export type DestroyDocumentFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyDocumentFile. */
export type DestroyDocumentFilePayload = {
  __typename?: 'DestroyDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocuments */
export type DestroyDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of DestroyDocuments. */
export type DestroyDocumentsPayload = {
  __typename?: 'DestroyDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyPaymentMethod */
export type DestroyPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPaymentMethod. */
export type DestroyPaymentMethodPayload = {
  __typename?: 'DestroyPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of DestroyPeople */
export type DestroyPeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of DestroyPeople. */
export type DestroyPeoplePayload = {
  __typename?: 'DestroyPeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

/** Autogenerated input type of DestroyPolicyDocument */
export type DestroyPolicyDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPolicyDocument. */
export type DestroyPolicyDocumentPayload = {
  __typename?: 'DestroyPolicyDocumentPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyRoutineGuideline */
export type DestroyRoutineGuidelineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyRoutineGuideline. */
export type DestroyRoutineGuidelinePayload = {
  __typename?: 'DestroyRoutineGuidelinePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroySignature */
export type DestroySignatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroySignature. */
export type DestroySignaturePayload = {
  __typename?: 'DestroySignaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  signature?: Maybe<Signature>;
};

/** Autogenerated input type of DestroyWbHandler */
export type DestroyWbHandlerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyWbHandler. */
export type DestroyWbHandlerPayload = {
  __typename?: 'DestroyWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of DestroyWbReportFile */
export type DestroyWbReportFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyWbReportFile. */
export type DestroyWbReportFilePayload = {
  __typename?: 'DestroyWbReportFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type Document = Node & {
  __typename?: 'Document';
  account: Account;
  activatedOn?: Maybe<Scalars['String']['output']>;
  activation?: Maybe<DocumentActivationEnum>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bulk: Scalars['Boolean']['output'];
  bulkSignatures: Array<Signature>;
  category?: Maybe<Scalars['String']['output']>;
  copyRecipients: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  documentFiles?: Maybe<Array<DocumentFile>>;
  documentNumber: Scalars['String']['output'];
  expiration?: Maybe<DocumentExpirationEnum>;
  expirationMonths?: Maybe<Scalars['Int']['output']>;
  expiredOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interactedAt?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owned: Scalars['Boolean']['output'];
  refusedAt?: Maybe<Scalars['String']['output']>;
  senderAccount?: Maybe<Account>;
  sentAt?: Maybe<Scalars['String']['output']>;
  signatures: Array<Signature>;
  signedAt?: Maybe<Scalars['String']['output']>;
  signedDocument?: Maybe<Attachment>;
  status?: Maybe<DocumentStatusEnum>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  terminatedCopyRecipients: Array<Scalars['String']['output']>;
  terminatedOn?: Maybe<Scalars['String']['output']>;
  terminationReason?: Maybe<Scalars['String']['output']>;
  type: DocumentTypeEnum;
};

export enum DocumentActivationEnum {
  Date = 'DATE',
  Signature = 'SIGNATURE'
}

export type DocumentAttributes = {
  activatedOn?: InputMaybe<Scalars['String']['input']>;
  activation?: InputMaybe<DocumentActivationEnum>;
  bulk?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  copyRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  expiration?: InputMaybe<DocumentExpirationEnum>;
  expirationMonths?: InputMaybe<Scalars['Int']['input']>;
  expiredOn?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  signatures?: InputMaybe<Array<SignatureAttributes>>;
  type?: InputMaybe<DocumentTypeEnum>;
};

export enum DocumentExpirationEnum {
  Activation = 'ACTIVATION',
  Date = 'DATE',
  None = 'NONE'
}

export type DocumentFile = Node & {
  __typename?: 'DocumentFile';
  file: Attachment;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentFileAttributes = {
  file: Base64AttachmentAttributes;
  name: Scalars['String']['input'];
};

export type DocumentPagination = {
  __typename?: 'DocumentPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Document>;
  total: Scalars['Int']['output'];
};

export enum DocumentStatusEnum {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED'
}

export enum DocumentTypeEnum {
  Basic = 'BASIC',
  Criipto = 'CRIIPTO'
}

export type DocumentsFilter = {
  activatedOnFrom?: InputMaybe<Scalars['String']['input']>;
  activatedOnTo?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['String']['input']>;
  createdAtTo?: InputMaybe<Scalars['String']['input']>;
  expiredOnFrom?: InputMaybe<Scalars['String']['input']>;
  expiredOnTo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<DocumentsRelationEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
  signer?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentStatusEnum>;
};

export enum DocumentsRelationEnum {
  Received = 'RECEIVED',
  Sent = 'SENT'
}

export type ELearningCheck = {
  __typename?: 'ELearningCheck';
  documents: Array<Attachment>;
};

export enum ELearningCourseEnum {
  Generic = 'GENERIC',
  None = 'NONE',
  Sports = 'SPORTS'
}

export type EducationCheck = {
  __typename?: 'EducationCheck';
  country?: Maybe<Scalars['String']['output']>;
  degree?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Attachment>>;
  finishMonth?: Maybe<Scalars['String']['output']>;
  institution?: Maybe<Scalars['String']['output']>;
  startMonth?: Maybe<Scalars['String']['output']>;
};

export type EducationCheckAttributes = {
  country?: InputMaybe<Scalars['String']['input']>;
  degree?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  finishMonth?: InputMaybe<Scalars['String']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  startMonth?: InputMaybe<Scalars['String']['input']>;
};

export enum EntityTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type Error = {
  __typename?: 'Error';
  code: ErrorCodeEnum;
  path?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum ErrorCodeEnum {
  AccountBlocked = 'ACCOUNT_BLOCKED',
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AttemptsLimitExceeded = 'ATTEMPTS_LIMIT_EXCEEDED',
  AuthLevel_2Required = 'AUTH_LEVEL_2_REQUIRED',
  BankIdVerificationRequired = 'BANK_ID_VERIFICATION_REQUIRED',
  Blank = 'BLANK',
  BrregQueryFailed = 'BRREG_QUERY_FAILED',
  ClientEmailMissing = 'CLIENT_EMAIL_MISSING',
  ClientInsolvent = 'CLIENT_INSOLVENT',
  DeliveryFrequencyExceeded = 'DELIVERY_FREQUENCY_EXCEEDED',
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  DocumentSigned = 'DOCUMENT_SIGNED',
  Immutable = 'IMMUTABLE',
  ImportError = 'IMPORT_ERROR',
  Inclusion = 'INCLUSION',
  Invalid = 'INVALID',
  InvalidAction = 'INVALID_ACTION',
  InvalidFile = 'INVALID_FILE',
  InvalidInvoicedOn = 'INVALID_INVOICED_ON',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidToken = 'INVALID_TOKEN',
  InvalidTransition = 'INVALID_TRANSITION',
  InvoiceCalculationFailed = 'INVOICE_CALCULATION_FAILED',
  InvoiceNotCredited = 'INVOICE_NOT_CREDITED',
  InvoiceNotOrganization = 'INVOICE_NOT_ORGANIZATION',
  InvoiceNotPublished = 'INVOICE_NOT_PUBLISHED',
  InvoiceNotSubmitted = 'INVOICE_NOT_SUBMITTED',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  JobCheckPending = 'JOB_CHECK_PENDING',
  JobCheckSucceeded = 'JOB_CHECK_SUCCEEDED',
  LineMismatch = 'LINE_MISMATCH',
  OccupationCodeDisabled = 'OCCUPATION_CODE_DISABLED',
  OrganizationRoleCheckFailed = 'ORGANIZATION_ROLE_CHECK_FAILED',
  OtpExpired = 'OTP_EXPIRED',
  OtpExpiredAndResent = 'OTP_EXPIRED_AND_RESENT',
  PaymentProviderError = 'PAYMENT_PROVIDER_ERROR',
  PersonalAccountOwnsTeams = 'PERSONAL_ACCOUNT_OWNS_TEAMS',
  PersonalNumberMismatch = 'PERSONAL_NUMBER_MISMATCH',
  Present = 'PRESENT',
  SenderEqualsReceiver = 'SENDER_EQUALS_RECEIVER',
  SmsSendingFailed = 'SMS_SENDING_FAILED',
  SubscriptionLimitExceeded = 'SUBSCRIPTION_LIMIT_EXCEEDED',
  Taken = 'TAKEN',
  TfaRequired = 'TFA_REQUIRED',
  TfaSendCooldown = 'TFA_SEND_COOLDOWN',
  TokenExpired = 'TOKEN_EXPIRED',
  TooBig = 'TOO_BIG',
  TooBigOrEqual = 'TOO_BIG_OR_EQUAL',
  TooShort = 'TOO_SHORT',
  TooSmall = 'TOO_SMALL',
  TooSmallOrEqual = 'TOO_SMALL_OR_EQUAL',
  TransitionFailed = 'TRANSITION_FAILED',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyConfirmed = 'USER_ALREADY_CONFIRMED',
  UserBlocked = 'USER_BLOCKED',
  UserExists = 'USER_EXISTS',
  UserNotConfirmed = 'USER_NOT_CONFIRMED',
  UserNotFound = 'USER_NOT_FOUND',
  ValidationFailed = 'VALIDATION_FAILED',
  VerificationFailed = 'VERIFICATION_FAILED'
}

export type Event = {
  __typename?: 'Event';
  actor: ActorEnum;
  eventType: EventTypeEnum;
  occurredAt: Scalars['String']['output'];
};

export enum EventTypeEnum {
  AccountDestroyed = 'ACCOUNT_DESTROYED',
  AccountMerged = 'ACCOUNT_MERGED',
  AttachmentDownloaded = 'ATTACHMENT_DOWNLOADED',
  CheckCompleted = 'CHECK_COMPLETED',
  CheckFileAnalysesRefuted = 'CHECK_FILE_ANALYSES_REFUTED',
  CheckFileAnalysesSucceeded = 'CHECK_FILE_ANALYSES_SUCCEEDED',
  CheckListAutoReminderSent = 'CHECK_LIST_AUTO_REMINDER_SENT',
  CheckListCancelled = 'CHECK_LIST_CANCELLED',
  CheckListCompleted = 'CHECK_LIST_COMPLETED',
  CheckListConsented = 'CHECK_LIST_CONSENTED',
  CheckListFinalReminderSent = 'CHECK_LIST_FINAL_REMINDER_SENT',
  CheckListPurposeLetterGenerated = 'CHECK_LIST_PURPOSE_LETTER_GENERATED',
  CheckListRefused = 'CHECK_LIST_REFUSED',
  CheckListReminderSent = 'CHECK_LIST_REMINDER_SENT',
  CheckListRequested = 'CHECK_LIST_REQUESTED',
  CheckOverridden = 'CHECK_OVERRIDDEN',
  CheckRetry = 'CHECK_RETRY',
  CheckSubmitted = 'CHECK_SUBMITTED',
  DocumentCancelled = 'DOCUMENT_CANCELLED',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  DocumentSigned = 'DOCUMENT_SIGNED',
  ExportedToFiken = 'EXPORTED_TO_FIKEN',
  FikenRequest = 'FIKEN_REQUEST',
  IdentityCreated = 'IDENTITY_CREATED',
  IdentityDeleted = 'IDENTITY_DELETED',
  IdentityUpdated = 'IDENTITY_UPDATED',
  SignatoryDocumentStatusChanged = 'SIGNATORY_DOCUMENT_STATUS_CHANGED',
  SignatoryDownloadLinkOpened = 'SIGNATORY_DOWNLOAD_LINK_OPENED',
  SignatoryRejected = 'SIGNATORY_REJECTED',
  SignatorySigned = 'SIGNATORY_SIGNED',
  SignatorySignError = 'SIGNATORY_SIGN_ERROR',
  SignatorySignLinkOpened = 'SIGNATORY_SIGN_LINK_OPENED',
  SignatureOrderExpired = 'SIGNATURE_ORDER_EXPIRED',
  TeamAccountRegistered = 'TEAM_ACCOUNT_REGISTERED',
  TeamAccountUpdated = 'TEAM_ACCOUNT_UPDATED',
  UserUpdated = 'USER_UPDATED'
}

export type FeatureCounts = {
  __typename?: 'FeatureCounts';
  accessControl?: Maybe<Scalars['Int']['output']>;
  advancedCaseManagement?: Maybe<Scalars['Int']['output']>;
  archive?: Maybe<Scalars['Int']['output']>;
  bulkSign?: Maybe<Scalars['Int']['output']>;
  criminalRecordCheck?: Maybe<Scalars['Int']['output']>;
  customApiIntegrations?: Maybe<Scalars['Int']['output']>;
  customBranding?: Maybe<Scalars['Int']['output']>;
  customELearning?: Maybe<Scalars['Int']['output']>;
  customisedSolution?: Maybe<Scalars['Int']['output']>;
  departments?: Maybe<Scalars['Int']['output']>;
  duplicateFilter?: Maybe<Scalars['Int']['output']>;
  encryption?: Maybe<Scalars['Int']['output']>;
  export?: Maybe<Scalars['Int']['output']>;
  feedback?: Maybe<Scalars['Int']['output']>;
  feedbackActivation?: Maybe<Scalars['Int']['output']>;
  import?: Maybe<Scalars['Int']['output']>;
  keyAccountManager?: Maybe<Scalars['Int']['output']>;
  members?: Maybe<Scalars['Int']['output']>;
  modules?: Maybe<Scalars['Int']['output']>;
  onboardingTraining?: Maybe<Scalars['Int']['output']>;
  qrCodes?: Maybe<Scalars['Int']['output']>;
  unlimitedStorage?: Maybe<Scalars['Int']['output']>;
  voiceReporting?: Maybe<Scalars['Int']['output']>;
  wallet?: Maybe<Scalars['Int']['output']>;
  whistleblowing?: Maybe<Scalars['Int']['output']>;
};

export enum FeatureTypeEnum {
  AccessControl = 'ACCESS_CONTROL',
  AdvancedCaseManagement = 'ADVANCED_CASE_MANAGEMENT',
  Archive = 'ARCHIVE',
  BulkSign = 'BULK_SIGN',
  CriminalRecordCheck = 'CRIMINAL_RECORD_CHECK',
  CustomisedSolution = 'CUSTOMISED_SOLUTION',
  CustomApiIntegrations = 'CUSTOM_API_INTEGRATIONS',
  CustomBranding = 'CUSTOM_BRANDING',
  CustomELearning = 'CUSTOM_E_LEARNING',
  Departments = 'DEPARTMENTS',
  DuplicateFilter = 'DUPLICATE_FILTER',
  Encryption = 'ENCRYPTION',
  Export = 'EXPORT',
  Feedback = 'FEEDBACK',
  FeedbackActivation = 'FEEDBACK_ACTIVATION',
  Import = 'IMPORT',
  KeyAccountManager = 'KEY_ACCOUNT_MANAGER',
  Modules = 'MODULES',
  OnboardingTraining = 'ONBOARDING_TRAINING',
  QrCodes = 'QR_CODES',
  UnlimitedStorage = 'UNLIMITED_STORAGE',
  VoiceReporting = 'VOICE_REPORTING',
  Wallet = 'WALLET',
  Whistleblowing = 'WHISTLEBLOWING'
}

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Identity = Node & {
  __typename?: 'Identity';
  country: CountryCodeEnum;
  createdAt: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  temporary: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export type IdentityCheck = {
  __typename?: 'IdentityCheck';
  identity?: Maybe<Identity>;
};

export type IdentityCheckAttributes = {
  identityId: Scalars['ID']['input'];
};

export enum IdentityCountryCodeEnum {
  Dk = 'DK',
  Fi = 'FI',
  No = 'NO',
  Se = 'SE'
}

export type InformationCheck = {
  __typename?: 'InformationCheck';
  documents: Array<Attachment>;
};

export enum LanguageEnum {
  En = 'EN',
  Nb = 'NB'
}

export type LegacyFeatures = {
  __typename?: 'LegacyFeatures';
  checks: Scalars['Boolean']['output'];
  eSignatures: Scalars['Boolean']['output'];
  invoices: Scalars['Boolean']['output'];
  payouts: Scalars['Boolean']['output'];
  whistleblowing: Scalars['Boolean']['output'];
  workflows: Scalars['Boolean']['output'];
};

export type ListCompany = {
  __typename?: 'ListCompany';
  accountExists: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  markedAsBankruptcy?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgNumber: Scalars['String']['output'];
  underForcedLiquidation?: Maybe<Scalars['Boolean']['output']>;
  underLiquidation?: Maybe<Scalars['Boolean']['output']>;
};

export type ListCompanyPagination = {
  __typename?: 'ListCompanyPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<ListCompany>;
  total: Scalars['Int']['output'];
};

export type MembersFilter = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  isWbHandler?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAccountMember?: Maybe<AddAccountMemberPayload>;
  addTfaPhoneNumber?: Maybe<AddTfaPhoneNumberPayload>;
  archiveCheckLists?: Maybe<ArchiveCheckListsPayload>;
  archiveDocuments?: Maybe<ArchiveDocumentsPayload>;
  archivePeople?: Maybe<ArchivePeoplePayload>;
  cancelAccountDeletion?: Maybe<CancelAccountDeletionPayload>;
  cancelCheckLists?: Maybe<CancelCheckListsPayload>;
  cancelDocuments?: Maybe<CancelDocumentsPayload>;
  cancelSubscription?: Maybe<CancelSubscriptionPayload>;
  closeWbReport?: Maybe<CloseWbReportPayload>;
  confirmTfaPhoneNumber?: Maybe<ConfirmTfaPhoneNumberPayload>;
  consentCheckList?: Maybe<ConsentCheckListPayload>;
  createCheckLists?: Maybe<CreateCheckListsPayload>;
  createCheckRequest?: Maybe<CreateCheckRequestPayload>;
  createDepartment?: Maybe<CreateDepartmentPayload>;
  createDocument?: Maybe<CreateDocumentPayload>;
  createDocumentFile?: Maybe<CreateDocumentFilePayload>;
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  createPolicyDocument?: Maybe<CreatePolicyDocumentPayload>;
  createRoutineGuideline?: Maybe<CreateRoutineGuidelinePayload>;
  createSession?: Maybe<CreateSessionPayload>;
  createSessionOtp?: Maybe<CreateSessionOtpPayload>;
  createSignatures?: Maybe<CreateSignaturesPayload>;
  createStripeCard?: Maybe<CreateStripeCardPayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createTeamAccount?: Maybe<CreateTeamAccountPayload>;
  createVeriffSession?: Maybe<CreateVeriffSessionPayload>;
  createWbHandler?: Maybe<CreateWbHandlerPayload>;
  createWbMessage?: Maybe<CreateWbMessagePayload>;
  createWbReport?: Maybe<CreateWbReportPayload>;
  createWbReportFile?: Maybe<CreateWbReportFilePayload>;
  criiptoVerify?: Maybe<CriiptoVerifyPayload>;
  deleteAccount?: Maybe<DeleteAccountPayload>;
  deleteIdentity?: Maybe<DeleteIdentityPayload>;
  deleteWbReport?: Maybe<DeleteWbReportPayload>;
  destroyAccountLogo?: Maybe<DestroyAccountLogoPayload>;
  destroyAccountSymbol?: Maybe<DestroyAccountSymbolPayload>;
  destroyCheckList?: Maybe<DestroyCheckListPayload>;
  destroyCheckRequestFile?: Maybe<DestroyCheckRequestFilePayload>;
  destroyDepartment?: Maybe<DestroyDepartmentPayload>;
  destroyDocumentFile?: Maybe<DestroyDocumentFilePayload>;
  destroyDocuments?: Maybe<DestroyDocumentsPayload>;
  destroyPaymentMethod?: Maybe<DestroyPaymentMethodPayload>;
  destroyPeople?: Maybe<DestroyPeoplePayload>;
  destroyPolicyDocument?: Maybe<DestroyPolicyDocumentPayload>;
  destroyRoutineGuideline?: Maybe<DestroyRoutineGuidelinePayload>;
  destroySignature?: Maybe<DestroySignaturePayload>;
  destroyWbHandler?: Maybe<DestroyWbHandlerPayload>;
  destroyWbReportFile?: Maybe<DestroyWbReportFilePayload>;
  notifyDocuments?: Maybe<NotifyDocumentsPayload>;
  notifySignature?: Maybe<NotifySignaturePayload>;
  onboard?: Maybe<OnboardPayload>;
  overrideCheck?: Maybe<OverrideCheckPayload>;
  prevalidateCheckFile?: Maybe<PrevalidateCheckFilePayload>;
  publicCreateWbMessage?: Maybe<PublicCreateWbMessagePayload>;
  publicUpdateWbReport?: Maybe<PublicUpdateWbReportPayload>;
  refreshSessionOtp?: Maybe<RefreshSessionOtpPayload>;
  refuseCheckList?: Maybe<RefuseCheckListPayload>;
  refuseDocument?: Maybe<RefuseDocumentPayload>;
  registerAccount?: Maybe<RegisterAccountPayload>;
  registerTeamAccount?: Maybe<RegisterTeamAccountPayload>;
  remindCheckLists?: Maybe<RemindCheckListsPayload>;
  removeAccountMember?: Maybe<RemoveAccountMemberPayload>;
  removeTfaPhoneNumber?: Maybe<RemoveTfaPhoneNumberPayload>;
  reopenWbReport?: Maybe<ReopenWbReportPayload>;
  resendTfaCode?: Maybe<ResendTfaCodePayload>;
  resendTfaPhoneNumberConfirmation?: Maybe<ResendTfaPhoneNumberConfirmationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendDocument?: Maybe<SendDocumentPayload>;
  sendPasswordResetInstructions?: Maybe<SendPasswordResetInstructionsPayload>;
  sendSmsConfirmation?: Maybe<SendSmsConfirmationPayload>;
  sendTeamWelcome?: Maybe<SendTeamWelcomePayload>;
  setAccountOwner?: Maybe<SetAccountOwnerPayload>;
  setDefaultIdentity?: Maybe<SetDefaultIdentityPayload>;
  setDefaultPaymentMethod?: Maybe<SetDefaultPaymentMethodPayload>;
  setDefaultWbHandler?: Maybe<SetDefaultWbHandlerPayload>;
  setPeopleDepartments?: Maybe<SetPeopleDepartmentsPayload>;
  setTeamRole?: Maybe<SetTeamRolePayload>;
  signDocument?: Maybe<SignDocumentPayload>;
  submitCheck?: Maybe<SubmitCheckPayload>;
  submitCheckRequest?: Maybe<SubmitCheckRequestPayload>;
  submitDocument?: Maybe<SubmitDocumentPayload>;
  submitWbReport?: Maybe<SubmitWbReportPayload>;
  switchAccount?: Maybe<SwitchAccountPayload>;
  terminateDocument?: Maybe<TerminateDocumentPayload>;
  unarchiveCheckLists?: Maybe<UnarchiveCheckListsPayload>;
  unarchiveDocuments?: Maybe<UnarchiveDocumentsPayload>;
  unarchivePeople?: Maybe<UnarchivePeoplePayload>;
  updateAccount?: Maybe<UpdateAccountPayload>;
  updateAccountMember?: Maybe<UpdateAccountMemberPayload>;
  updateCheckList?: Maybe<UpdateCheckListPayload>;
  updateCheckRequest?: Maybe<UpdateCheckRequestPayload>;
  updateDepartment?: Maybe<UpdateDepartmentPayload>;
  updateDocument?: Maybe<UpdateDocumentPayload>;
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateWbHandler?: Maybe<UpdateWbHandlerPayload>;
  updateWbReport?: Maybe<UpdateWbReportPayload>;
  uploadCheckLists?: Maybe<UploadCheckListsPayload>;
  uploadCheckRequestFile?: Maybe<UploadCheckRequestFilePayload>;
  uploadSignatures?: Maybe<UploadSignaturesPayload>;
  useSessionOtp?: Maybe<UseSessionOtpPayload>;
  validatePasswordResetToken?: Maybe<ValidatePasswordResetTokenPayload>;
  validateSessionTfa?: Maybe<ValidateSessionTfaPayload>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberPayload>;
};


export type MutationAddAccountMemberArgs = {
  input: AddAccountMemberInput;
};


export type MutationAddTfaPhoneNumberArgs = {
  input: AddTfaPhoneNumberInput;
};


export type MutationArchiveCheckListsArgs = {
  input: ArchiveCheckListsInput;
};


export type MutationArchiveDocumentsArgs = {
  input: ArchiveDocumentsInput;
};


export type MutationArchivePeopleArgs = {
  input: ArchivePeopleInput;
};


export type MutationCancelAccountDeletionArgs = {
  input: CancelAccountDeletionInput;
};


export type MutationCancelCheckListsArgs = {
  input: CancelCheckListsInput;
};


export type MutationCancelDocumentsArgs = {
  input: CancelDocumentsInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCloseWbReportArgs = {
  input: CloseWbReportInput;
};


export type MutationConfirmTfaPhoneNumberArgs = {
  input: ConfirmTfaPhoneNumberInput;
};


export type MutationConsentCheckListArgs = {
  input: ConsentCheckListInput;
};


export type MutationCreateCheckListsArgs = {
  input: CreateCheckListsInput;
};


export type MutationCreateCheckRequestArgs = {
  input: CreateCheckRequestInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentFileArgs = {
  input: CreateDocumentFileInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePolicyDocumentArgs = {
  input: CreatePolicyDocumentInput;
};


export type MutationCreateRoutineGuidelineArgs = {
  input: CreateRoutineGuidelineInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateSessionOtpArgs = {
  input: CreateSessionOtpInput;
};


export type MutationCreateSignaturesArgs = {
  input: CreateSignaturesInput;
};


export type MutationCreateStripeCardArgs = {
  input: CreateStripeCardInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateTeamAccountArgs = {
  input: CreateTeamAccountInput;
};


export type MutationCreateVeriffSessionArgs = {
  input: CreateVeriffSessionInput;
};


export type MutationCreateWbHandlerArgs = {
  input: CreateWbHandlerInput;
};


export type MutationCreateWbMessageArgs = {
  input: CreateWbMessageInput;
};


export type MutationCreateWbReportArgs = {
  input: CreateWbReportInput;
};


export type MutationCreateWbReportFileArgs = {
  input: CreateWbReportFileInput;
};


export type MutationCriiptoVerifyArgs = {
  input: CriiptoVerifyInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteIdentityArgs = {
  input: DeleteIdentityInput;
};


export type MutationDeleteWbReportArgs = {
  input: DeleteWbReportInput;
};


export type MutationDestroyAccountLogoArgs = {
  input: DestroyAccountLogoInput;
};


export type MutationDestroyAccountSymbolArgs = {
  input: DestroyAccountSymbolInput;
};


export type MutationDestroyCheckListArgs = {
  input: DestroyCheckListInput;
};


export type MutationDestroyCheckRequestFileArgs = {
  input: DestroyCheckRequestFileInput;
};


export type MutationDestroyDepartmentArgs = {
  input: DestroyDepartmentInput;
};


export type MutationDestroyDocumentFileArgs = {
  input: DestroyDocumentFileInput;
};


export type MutationDestroyDocumentsArgs = {
  input: DestroyDocumentsInput;
};


export type MutationDestroyPaymentMethodArgs = {
  input: DestroyPaymentMethodInput;
};


export type MutationDestroyPeopleArgs = {
  input: DestroyPeopleInput;
};


export type MutationDestroyPolicyDocumentArgs = {
  input: DestroyPolicyDocumentInput;
};


export type MutationDestroyRoutineGuidelineArgs = {
  input: DestroyRoutineGuidelineInput;
};


export type MutationDestroySignatureArgs = {
  input: DestroySignatureInput;
};


export type MutationDestroyWbHandlerArgs = {
  input: DestroyWbHandlerInput;
};


export type MutationDestroyWbReportFileArgs = {
  input: DestroyWbReportFileInput;
};


export type MutationNotifyDocumentsArgs = {
  input: NotifyDocumentsInput;
};


export type MutationNotifySignatureArgs = {
  input: NotifySignatureInput;
};


export type MutationOnboardArgs = {
  input: OnboardInput;
};


export type MutationOverrideCheckArgs = {
  input: OverrideCheckInput;
};


export type MutationPrevalidateCheckFileArgs = {
  input: PrevalidateCheckFileInput;
};


export type MutationPublicCreateWbMessageArgs = {
  input: PublicCreateWbMessageInput;
};


export type MutationPublicUpdateWbReportArgs = {
  input: PublicUpdateWbReportInput;
};


export type MutationRefreshSessionOtpArgs = {
  input: RefreshSessionOtpInput;
};


export type MutationRefuseCheckListArgs = {
  input: RefuseCheckListInput;
};


export type MutationRefuseDocumentArgs = {
  input: RefuseDocumentInput;
};


export type MutationRegisterAccountArgs = {
  input: RegisterAccountInput;
};


export type MutationRegisterTeamAccountArgs = {
  input: RegisterTeamAccountInput;
};


export type MutationRemindCheckListsArgs = {
  input: RemindCheckListsInput;
};


export type MutationRemoveAccountMemberArgs = {
  input: RemoveAccountMemberInput;
};


export type MutationRemoveTfaPhoneNumberArgs = {
  input: RemoveTfaPhoneNumberInput;
};


export type MutationReopenWbReportArgs = {
  input: ReopenWbReportInput;
};


export type MutationResendTfaCodeArgs = {
  input: ResendTfaCodeInput;
};


export type MutationResendTfaPhoneNumberConfirmationArgs = {
  input: ResendTfaPhoneNumberConfirmationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendDocumentArgs = {
  input: SendDocumentInput;
};


export type MutationSendPasswordResetInstructionsArgs = {
  input: SendPasswordResetInstructionsInput;
};


export type MutationSendSmsConfirmationArgs = {
  input: SendSmsConfirmationInput;
};


export type MutationSendTeamWelcomeArgs = {
  input: SendTeamWelcomeInput;
};


export type MutationSetAccountOwnerArgs = {
  input: SetAccountOwnerInput;
};


export type MutationSetDefaultIdentityArgs = {
  input: SetDefaultIdentityInput;
};


export type MutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultPaymentMethodInput;
};


export type MutationSetDefaultWbHandlerArgs = {
  input: SetDefaultWbHandlerInput;
};


export type MutationSetPeopleDepartmentsArgs = {
  input: SetPeopleDepartmentsInput;
};


export type MutationSetTeamRoleArgs = {
  input: SetTeamRoleInput;
};


export type MutationSignDocumentArgs = {
  input: SignDocumentInput;
};


export type MutationSubmitCheckArgs = {
  input: SubmitCheckInput;
};


export type MutationSubmitCheckRequestArgs = {
  input: SubmitCheckRequestInput;
};


export type MutationSubmitDocumentArgs = {
  input: SubmitDocumentInput;
};


export type MutationSubmitWbReportArgs = {
  input: SubmitWbReportInput;
};


export type MutationSwitchAccountArgs = {
  input: SwitchAccountInput;
};


export type MutationTerminateDocumentArgs = {
  input: TerminateDocumentInput;
};


export type MutationUnarchiveCheckListsArgs = {
  input: UnarchiveCheckListsInput;
};


export type MutationUnarchiveDocumentsArgs = {
  input: UnarchiveDocumentsInput;
};


export type MutationUnarchivePeopleArgs = {
  input: UnarchivePeopleInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountMemberArgs = {
  input: UpdateAccountMemberInput;
};


export type MutationUpdateCheckListArgs = {
  input: UpdateCheckListInput;
};


export type MutationUpdateCheckRequestArgs = {
  input: UpdateCheckRequestInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWbHandlerArgs = {
  input: UpdateWbHandlerInput;
};


export type MutationUpdateWbReportArgs = {
  input: UpdateWbReportInput;
};


export type MutationUploadCheckListsArgs = {
  input: UploadCheckListsInput;
};


export type MutationUploadCheckRequestFileArgs = {
  input: UploadCheckRequestFileInput;
};


export type MutationUploadSignaturesArgs = {
  input: UploadSignaturesInput;
};


export type MutationUseSessionOtpArgs = {
  input: UseSessionOtpInput;
};


export type MutationValidatePasswordResetTokenArgs = {
  input: ValidatePasswordResetTokenInput;
};


export type MutationValidateSessionTfaArgs = {
  input: ValidateSessionTfaInput;
};


export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  full: Scalars['String']['output'];
  last: Scalars['String']['output'];
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of NotifyDocuments */
export type NotifyDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of NotifyDocuments. */
export type NotifyDocumentsPayload = {
  __typename?: 'NotifyDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of NotifySignature */
export type NotifySignatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of NotifySignature. */
export type NotifySignaturePayload = {
  __typename?: 'NotifySignaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  signature?: Maybe<Signature>;
};

/** Autogenerated input type of Onboard */
export type OnboardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Onboard. */
export type OnboardPayload = {
  __typename?: 'OnboardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  onboarded: Scalars['Boolean']['output'];
};

export type OpenSourceSearchCheck = {
  __typename?: 'OpenSourceSearchCheck';
  socialMediaAccounts?: Maybe<Array<SocialMediaAccount>>;
};

export type OpenSourceSearchCheckAttributes = {
  socialMediaAccounts: Array<SocialMediaAccountAttributes>;
};

export type OverrideCheckAttributes = {
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of OverrideCheck */
export type OverrideCheckInput = {
  attributes?: InputMaybe<OverrideCheckAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OverrideCheck. */
export type OverrideCheckPayload = {
  __typename?: 'OverrideCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  address?: Maybe<Address>;
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardExpMonth?: Maybe<Scalars['String']['output']>;
  cardExpYear?: Maybe<Scalars['Int']['output']>;
  cardLast4?: Maybe<Scalars['Int']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  entityType: EntityTypeEnum;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orderReference?: Maybe<Scalars['String']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  pending: Scalars['Boolean']['output'];
  type: PaymentMethodTypeEnum;
};

export type PaymentMethodPagination = {
  __typename?: 'PaymentMethodPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<PaymentMethod>;
  total: Scalars['Int']['output'];
};

export enum PaymentMethodTypeEnum {
  Invoice = 'INVOICE',
  StripeCard = 'STRIPE_CARD'
}

export type PeopleFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<PeopleRelationEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum PeopleRelationEnum {
  Checks = 'CHECKS',
  Documents = 'DOCUMENTS'
}

export enum PeopleSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC'
}

export type PepRelation = {
  __typename?: 'PepRelation';
  address?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderEnum>;
  identifier?: Maybe<Scalars['String']['output']>;
  identifierUnavailable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  terminatedOn?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: PepRelationTypeEnum;
};

export type PepRelationAttributes = {
  address?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderEnum>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  identifierUnavailable: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  organization: Scalars['String']['input'];
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  terminatedOn?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: PepRelationTypeEnum;
};

export enum PepRelationTypeEnum {
  ChildsPartner = 'CHILDS_PARTNER',
  Other = 'OTHER',
  Parent = 'PARENT',
  Partner = 'PARTNER'
}

export type Person = Node & {
  __typename?: 'Person';
  archivedAt?: Maybe<Scalars['String']['output']>;
  checkListCount: Scalars['Int']['output'];
  checkLists: Array<CheckList>;
  departments: Array<Department>;
  documentCount: Scalars['Int']['output'];
  documents: Array<Document>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationName: Name;
  legalName?: Maybe<Name>;
  personalAccount: Account;
  phone?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Name>;
  teamMember: Scalars['Boolean']['output'];
  teamRole?: Maybe<TeamRoleEnum>;
};

export type PersonPagination = {
  __typename?: 'PersonPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Person>;
  total: Scalars['Int']['output'];
};

export type PersonalNumberCheck = {
  __typename?: 'PersonalNumberCheck';
  files?: Maybe<Array<Attachment>>;
  firstName?: Maybe<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type PersonalNumberCheckAttributes = {
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneNumber = Node & {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  tokenSentAt?: Maybe<Scalars['String']['output']>;
  tokenVerifiedAt?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberPagination = {
  __typename?: 'PhoneNumberPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<PhoneNumber>;
  total: Scalars['Int']['output'];
};

export type PhoneNumberStruct = {
  __typename?: 'PhoneNumberStruct';
  countryCode?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberStructAttributes = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PrevalidateCheckFile */
export type PrevalidateCheckFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PrevalidateCheckFile. */
export type PrevalidateCheckFilePayload = {
  __typename?: 'PrevalidateCheckFilePayload';
  checkFileDocumentType?: Maybe<CheckFileDocumentTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export enum PriceCurrencyEnum {
  Dkk = 'DKK',
  Eur = 'EUR',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD'
}

export enum PriceIntervalEnum {
  Month = 'MONTH',
  OneTime = 'ONE_TIME',
  Year = 'YEAR'
}

export enum PricePlanEnum {
  HealthServiceYouthPluss = 'HEALTH_SERVICE_YOUTH_PLUSS',
  HealthServiceYouthPremium = 'HEALTH_SERVICE_YOUTH_PREMIUM',
  HealthServiceYouthPro = 'HEALTH_SERVICE_YOUTH_PRO',
  HealthServiceYouthStarter = 'HEALTH_SERVICE_YOUTH_STARTER',
  KindergartenAndSchoolPluss = 'KINDERGARTEN_AND_SCHOOL_PLUSS',
  KindergartenAndSchoolPremium = 'KINDERGARTEN_AND_SCHOOL_PREMIUM',
  KindergartenAndSchoolPro = 'KINDERGARTEN_AND_SCHOOL_PRO',
  KindergartenAndSchoolStarter = 'KINDERGARTEN_AND_SCHOOL_STARTER',
  KindergartenPluss = 'KINDERGARTEN_PLUSS',
  KindergartenPremium = 'KINDERGARTEN_PREMIUM',
  KindergartenPro = 'KINDERGARTEN_PRO',
  KindergartenStarter = 'KINDERGARTEN_STARTER',
  LanguageService = 'LANGUAGE_SERVICE',
  MarchingBandLarge = 'MARCHING_BAND_LARGE',
  MarchingBandMedium = 'MARCHING_BAND_MEDIUM',
  MarchingBandSmall = 'MARCHING_BAND_SMALL',
  Pluss = 'PLUSS',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  ReligiousPluss = 'RELIGIOUS_PLUSS',
  ReligiousPremium = 'RELIGIOUS_PREMIUM',
  ReligiousPro = 'RELIGIOUS_PRO',
  ReligiousStarter = 'RELIGIOUS_STARTER',
  SportsPluss = 'SPORTS_PLUSS',
  SportsPremium = 'SPORTS_PREMIUM',
  SportsPro = 'SPORTS_PRO',
  SportsStarter = 'SPORTS_STARTER',
  Starter = 'STARTER',
  VolunteerPluss = 'VOLUNTEER_PLUSS',
  VolunteerPremium = 'VOLUNTEER_PREMIUM',
  VolunteerPro = 'VOLUNTEER_PRO',
  VolunteerStarter = 'VOLUNTEER_STARTER'
}

export type Product = {
  __typename?: 'Product';
  amount: Scalars['Int']['output'];
  currency: PriceCurrencyEnum;
  limit?: Maybe<Scalars['Int']['output']>;
  type: ProductTypeEnum;
  usage?: Maybe<Scalars['Int']['output']>;
};

export type ProductMap = {
  __typename?: 'ProductMap';
  basicCheck: Product;
  basicPlusCheck: Product;
  comprehensiveCheck: Product;
  docuCheck: Product;
  eSignature: Product;
  enhancedCheck: Product;
  nordicIdCheck: Product;
  norskPolitiattest: Product;
};

export enum ProductTypeEnum {
  BasicCheck = 'BASIC_CHECK',
  BasicPlusCheck = 'BASIC_PLUS_CHECK',
  ComprehensiveCheck = 'COMPREHENSIVE_CHECK',
  DocuCheck = 'DOCU_CHECK',
  EnhancedCheck = 'ENHANCED_CHECK',
  ESignature = 'E_SIGNATURE',
  NordicIdCheck = 'NORDIC_ID_CHECK',
  NorskPolitiattest = 'NORSK_POLITIATTEST'
}

/** Autogenerated input type of PublicCreateWbMessage */
export type PublicCreateWbMessageInput = {
  accessCode: Scalars['String']['input'];
  attributes: WbMessageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reportNumber: Scalars['String']['input'];
  wbSlug: Scalars['String']['input'];
};

/** Autogenerated return type of PublicCreateWbMessage. */
export type PublicCreateWbMessagePayload = {
  __typename?: 'PublicCreateWbMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

/** Autogenerated input type of PublicUpdateWbReport */
export type PublicUpdateWbReportInput = {
  attributes: PublicWbReportAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PublicUpdateWbReport. */
export type PublicUpdateWbReportPayload = {
  __typename?: 'PublicUpdateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type PublicWbAccount = {
  __typename?: 'PublicWbAccount';
  logo?: Maybe<Attachment>;
  name?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  symbolDisplayPath?: Maybe<Scalars['String']['output']>;
  theme: AccountTheme;
  wbHandlers: Array<PublicWbHandler>;
  wbSettings: WbSettings;
  wbSlug: Scalars['String']['output'];
};

export type PublicWbHandler = {
  __typename?: 'PublicWbHandler';
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PublicWbReport = {
  __typename?: 'PublicWbReport';
  abandoned: Scalars['Boolean']['output'];
  accessCode: Scalars['String']['output'];
  account: PublicWbAccount;
  action?: Maybe<Scalars['String']['output']>;
  category?: Maybe<WbReportCategoryEnum>;
  closedAt?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastInteractionAt: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Scalars['String']['output']>;
  phoneNumber: PhoneNumberStruct;
  privacy: WbReportPrivacyEnum;
  reportNumber?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  status: WbReportStatusEnum;
  submittedAt?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  type?: Maybe<WbReportTypeEnum>;
  wbHandlers: Array<PublicWbHandler>;
  wbMessages: Array<WbMessage>;
};

export type PublicWbReportAttributes = {
  action?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<WbReportCategoryEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailField']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  people?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<PhoneNumberStructAttributes>;
  privacy?: InputMaybe<WbReportPrivacyEnum>;
  timing?: InputMaybe<Scalars['String']['input']>;
  wbHandlerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  checkSlug: Scalars['Boolean']['output'];
  companies: ListCompanyPagination;
  company?: Maybe<Company>;
  crcPurposes: CrcPurposePagination;
  departments: DepartmentPagination;
  documents: DocumentPagination;
  lastUsedPhoneNumber?: Maybe<PhoneNumber>;
  members: AccountPagination;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  paymentMethods: PaymentMethodPagination;
  people: PersonPagination;
  phoneNumbers: PhoneNumberPagination;
  publicDocument?: Maybe<Document>;
  publicSignature?: Maybe<Signature>;
  receivedCheckLists: CheckListPagination;
  relatedTeams: AccountPagination;
  sentCheckLists: CheckListPagination;
  session?: Maybe<Session>;
  tier?: Maybe<Tier>;
  tiers: TierPagination;
  wbAccount?: Maybe<PublicWbAccount>;
  wbHandlers: WbHandlerPagination;
  wbReport?: Maybe<PublicWbReport>;
  wbReports: WbReportPagination;
};


export type QueryCheckSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryCompaniesArgs = {
  includeSubunits?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompanyArgs = {
  orgNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCrcPurposesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDepartmentsArgs = {
  filter?: InputMaybe<DepartmentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDocumentsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMembersArgs = {
  filter?: InputMaybe<MembersFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentMethodsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPeopleArgs = {
  filter?: InputMaybe<PeopleFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PeopleSortByEnum>;
};


export type QueryPhoneNumbersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublicDocumentArgs = {
  token: Scalars['String']['input'];
};


export type QueryPublicSignatureArgs = {
  token: Scalars['String']['input'];
};


export type QueryReceivedCheckListsArgs = {
  filter?: InputMaybe<CheckListsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRelatedTeamsArgs = {
  filter?: InputMaybe<RelatedTeamsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySentCheckListsArgs = {
  filter?: InputMaybe<CheckListsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CheckListsSortByEnum>;
};


export type QueryTierArgs = {
  id: Scalars['String']['input'];
};


export type QueryTiersArgs = {
  filter?: InputMaybe<TiersFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWbAccountArgs = {
  wbSlug: Scalars['String']['input'];
};


export type QueryWbHandlersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWbReportArgs = {
  accessCode: Scalars['String']['input'];
  reportNumber?: InputMaybe<Scalars['String']['input']>;
  wbSlug: Scalars['String']['input'];
};


export type QueryWbReportsArgs = {
  filter?: InputMaybe<WbReportsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<WbReportsSortByEnum>;
};

/** Autogenerated input type of RefreshSessionOtp */
export type RefreshSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of RefreshSessionOtp. */
export type RefreshSessionOtpPayload = {
  __typename?: 'RefreshSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RefuseCheckList */
export type RefuseCheckListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  refuseReason: Scalars['String']['input'];
};

/** Autogenerated return type of RefuseCheckList. */
export type RefuseCheckListPayload = {
  __typename?: 'RefuseCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RefuseDocument */
export type RefuseDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RefuseDocument. */
export type RefuseDocumentPayload = {
  __typename?: 'RefuseDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type RegisterAccountAttributes = {
  email: Scalars['EmailField']['input'];
  language?: InputMaybe<LanguageEnum>;
  referrer?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterAccount */
export type RegisterAccountInput = {
  attributes: RegisterAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partnerAccountId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RegisterAccount. */
export type RegisterAccountPayload = {
  __typename?: 'RegisterAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type RegisterTeamAccountAttributes = {
  address?: InputMaybe<AddressAttributes>;
  country: Scalars['String']['input'];
  email: Scalars['EmailField']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingConsent: Scalars['Boolean']['input'];
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNumber: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<AccountTagEnum>;
  tierId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterTeamAccount */
export type RegisterTeamAccountInput = {
  attributes: RegisterTeamAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RegisterTeamAccount. */
export type RegisterTeamAccountPayload = {
  __typename?: 'RegisterTeamAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type RelatedCountriesCheck = {
  __typename?: 'RelatedCountriesCheck';
  affiliationCountries: Array<Scalars['String']['output']>;
  assetsCountries: Array<Scalars['String']['output']>;
  familyCountries: Array<Scalars['String']['output']>;
  incomeCountries: Array<Scalars['String']['output']>;
  legalCountries: Array<Scalars['String']['output']>;
  presenceCountries: Array<Scalars['String']['output']>;
};

export type RelatedCountriesCheckAttributes = {
  affiliationCountries: Array<Scalars['String']['input']>;
  assetsCountries: Array<Scalars['String']['input']>;
  familyCountries: Array<Scalars['String']['input']>;
  incomeCountries: Array<Scalars['String']['input']>;
  legalCountries: Array<Scalars['String']['input']>;
  presenceCountries: Array<Scalars['String']['input']>;
};

export type RelatedTeamsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RemindCheckLists */
export type RemindCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of RemindCheckLists. */
export type RemindCheckListsPayload = {
  __typename?: 'RemindCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RemoveAccountMember */
export type RemoveAccountMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAccountMember. */
export type RemoveAccountMemberPayload = {
  __typename?: 'RemoveAccountMemberPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of RemoveTfaPhoneNumber */
export type RemoveTfaPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RemoveTfaPhoneNumber. */
export type RemoveTfaPhoneNumberPayload = {
  __typename?: 'RemoveTfaPhoneNumberPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ReopenWbReport */
export type ReopenWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ReopenWbReport. */
export type ReopenWbReportPayload = {
  __typename?: 'ReopenWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of ResendTfaCode */
export type ResendTfaCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sessionToken: Scalars['String']['input'];
};

/** Autogenerated return type of ResendTfaCode. */
export type ResendTfaCodePayload = {
  __typename?: 'ResendTfaCodePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ResendTfaPhoneNumberConfirmation */
export type ResendTfaPhoneNumberConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResendTfaPhoneNumberConfirmation. */
export type ResendTfaPhoneNumberConfirmationPayload = {
  __typename?: 'ResendTfaPhoneNumberConfirmationPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendDocument */
export type SendDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendDocument. */
export type SendDocumentPayload = {
  __typename?: 'SendDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendPasswordResetInstructions */
export type SendPasswordResetInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
};

/** Autogenerated return type of SendPasswordResetInstructions. */
export type SendPasswordResetInstructionsPayload = {
  __typename?: 'SendPasswordResetInstructionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendSmsConfirmation */
export type SendSmsConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

/** Autogenerated return type of SendSmsConfirmation. */
export type SendSmsConfirmationPayload = {
  __typename?: 'SendSmsConfirmationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendTeamWelcome */
export type SendTeamWelcomeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SendTeamWelcome. */
export type SendTeamWelcomePayload = {
  __typename?: 'SendTeamWelcomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type Session = {
  __typename?: 'Session';
  account: Account;
  accounts: Array<Account>;
  administrator?: Maybe<Scalars['String']['output']>;
  hasIdentity: Scalars['Boolean']['output'];
  needsOnboarding: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<PhoneNumber>;
  role?: Maybe<TeamRoleEnum>;
  user: User;
};

/** Autogenerated input type of SetAccountOwner */
export type SetAccountOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetAccountOwner. */
export type SetAccountOwnerPayload = {
  __typename?: 'SetAccountOwnerPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SetDefaultIdentity */
export type SetDefaultIdentityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultIdentity. */
export type SetDefaultIdentityPayload = {
  __typename?: 'SetDefaultIdentityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  identity?: Maybe<Identity>;
};

/** Autogenerated input type of SetDefaultPaymentMethod */
export type SetDefaultPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultPaymentMethod. */
export type SetDefaultPaymentMethodPayload = {
  __typename?: 'SetDefaultPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of SetDefaultWbHandler */
export type SetDefaultWbHandlerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SetDefaultWbHandler. */
export type SetDefaultWbHandlerPayload = {
  __typename?: 'SetDefaultWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of SetPeopleDepartments */
export type SetPeopleDepartmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departmentIds: Array<Scalars['ID']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of SetPeopleDepartments. */
export type SetPeopleDepartmentsPayload = {
  __typename?: 'SetPeopleDepartmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

/** Autogenerated input type of SetTeamRole */
export type SetTeamRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  role: TeamRoleEnum;
};

/** Autogenerated return type of SetTeamRole. */
export type SetTeamRolePayload = {
  __typename?: 'SetTeamRolePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type SignDocumentAttributes = {
  digitalSignature?: InputMaybe<Base64AttachmentAttributes>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SignDocument */
export type SignDocumentInput = {
  attributes: SignDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignDocument. */
export type SignDocumentPayload = {
  __typename?: 'SignDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Signature = Node & {
  __typename?: 'Signature';
  account?: Maybe<Account>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bulk: Scalars['Boolean']['output'];
  canSign: Scalars['Boolean']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  document: Document;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Name;
  notifiedAt?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  refusedAt?: Maybe<Scalars['String']['output']>;
  signUrl?: Maybe<Scalars['String']['output']>;
  signatureNumber: Scalars['Int']['output'];
  signedAt?: Maybe<Scalars['String']['output']>;
  status: SignatureStatusEnum;
};

export type SignatureAttributes = {
  designation?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum SignatureStatusEnum {
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED'
}

export type SocialMediaAccount = {
  __typename?: 'SocialMediaAccount';
  platform: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SocialMediaAccountAttributes = {
  platform: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type SpecificSourceSearchCheck = {
  __typename?: 'SpecificSourceSearchCheck';
  ambassador?: Maybe<Scalars['Boolean']['output']>;
  auditorBanker?: Maybe<Scalars['Boolean']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  headMinister?: Maybe<Scalars['Boolean']['output']>;
  internationalOrganization?: Maybe<Scalars['Boolean']['output']>;
  judge?: Maybe<Scalars['Boolean']['output']>;
  militaryOfficer?: Maybe<Scalars['Boolean']['output']>;
  nationalAssembly?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  pep: Scalars['Boolean']['output'];
  pepRelation: Scalars['Boolean']['output'];
  pepRelations?: Maybe<Array<PepRelation>>;
  politicalParty?: Maybe<Scalars['Boolean']['output']>;
  stateEnterprise?: Maybe<Scalars['Boolean']['output']>;
  terminatedOn?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SpecificSourceSearchCheckAttributes = {
  ambassador?: InputMaybe<Scalars['Boolean']['input']>;
  auditorBanker?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  headMinister?: InputMaybe<Scalars['Boolean']['input']>;
  internationalOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  judge?: InputMaybe<Scalars['Boolean']['input']>;
  militaryOfficer?: InputMaybe<Scalars['Boolean']['input']>;
  nationalAssembly?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  pep: Scalars['Boolean']['input'];
  pepRelation: Scalars['Boolean']['input'];
  pepRelations?: InputMaybe<Array<PepRelationAttributes>>;
  politicalParty?: InputMaybe<Scalars['Boolean']['input']>;
  stateEnterprise?: InputMaybe<Scalars['Boolean']['input']>;
  terminatedOn?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type StripeCardAttributes = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entityType: EntityTypeEnum;
  stripeSetupIntentId: Scalars['String']['input'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String']['output'];
};

/** Autogenerated input type of SubmitCheck */
export type SubmitCheckInput = {
  attributes?: InputMaybe<CheckAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitCheck. */
export type SubmitCheckPayload = {
  __typename?: 'SubmitCheckPayload';
  check?: Maybe<Check>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitCheckRequest */
export type SubmitCheckRequestInput = {
  attributes: CheckRequestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitCheckRequest. */
export type SubmitCheckRequestPayload = {
  __typename?: 'SubmitCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitDocument */
export type SubmitDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitDocument. */
export type SubmitDocumentPayload = {
  __typename?: 'SubmitDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitWbReport */
export type SubmitWbReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitWbReport. */
export type SubmitWbReportPayload = {
  __typename?: 'SubmitWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<PublicWbReport>;
};

export type Subscription = {
  __typename?: 'Subscription';
  currentPeriodEndAt: Scalars['String']['output'];
  currentPeriodStartAt: Scalars['String']['output'];
  downgradeTier?: Maybe<Tier>;
  downgradedAt?: Maybe<Scalars['String']['output']>;
  tier: Tier;
};

/** Autogenerated input type of SwitchAccount */
export type SwitchAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of SwitchAccount. */
export type SwitchAccountPayload = {
  __typename?: 'SwitchAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

export enum TeamRoleEnum {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type TerminateDocumentAttributes = {
  terminatedCopyRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  terminatedOn: Scalars['String']['input'];
  terminationReason: Scalars['String']['input'];
};

/** Autogenerated input type of TerminateDocument */
export type TerminateDocumentInput = {
  attributes: TerminateDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TerminateDocument. */
export type TerminateDocumentPayload = {
  __typename?: 'TerminateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Tier = {
  __typename?: 'Tier';
  amount: Scalars['Int']['output'];
  currency: PriceCurrencyEnum;
  features: Array<FeatureTypeEnum>;
  id: Scalars['String']['output'];
  interval: PriceIntervalEnum;
  memberLimit?: Maybe<Scalars['Int']['output']>;
  memberUsage?: Maybe<Scalars['Int']['output']>;
  products: ProductMap;
  type: TierTypeEnum;
};

export type TierPagination = {
  __typename?: 'TierPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<Tier>;
  total: Scalars['Int']['output'];
};

export enum TierTypeEnum {
  Basic = 'BASIC',
  Enterprise = 'ENTERPRISE',
  Plus = 'PLUS',
  Premium = 'PREMIUM'
}

export type TiersFilter = {
  currency?: InputMaybe<PriceCurrencyEnum>;
  interval?: InputMaybe<PriceIntervalEnum>;
};

export type UiFlags = {
  __typename?: 'UiFlags';
  hasReceivedCheckLists?: Maybe<Scalars['Boolean']['output']>;
  hasSentCheckLists?: Maybe<Scalars['Boolean']['output']>;
  shownIds: Array<Scalars['String']['output']>;
};

export type UiFlagsAttributes = {
  shownIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of UnarchiveCheckLists */
export type UnarchiveCheckListsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CheckListsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchiveCheckLists. */
export type UnarchiveCheckListsPayload = {
  __typename?: 'UnarchiveCheckListsPayload';
  checkLists?: Maybe<Array<CheckList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UnarchiveDocuments */
export type UnarchiveDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentsFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchiveDocuments. */
export type UnarchiveDocumentsPayload = {
  __typename?: 'UnarchiveDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  errors: Array<Error>;
};

/** Autogenerated input type of UnarchivePeople */
export type UnarchivePeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UnarchivePeople. */
export type UnarchivePeoplePayload = {
  __typename?: 'UnarchivePeoplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  people?: Maybe<Array<Person>>;
};

export type UpdateAccountAttributes = {
  address?: InputMaybe<AddressAttributes>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  industrySector?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageEnum>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Base64AttachmentAttributes>;
  theme?: InputMaybe<AccountThemeAttributes>;
  uiFlags?: InputMaybe<UiFlagsAttributes>;
  wbSettings?: InputMaybe<WbSettingsAttributes>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAccount */
export type UpdateAccountInput = {
  attributes: UpdateAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personal?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateAccountMember */
export type UpdateAccountMemberInput = {
  attributes: UpdateMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAccountMember. */
export type UpdateAccountMemberPayload = {
  __typename?: 'UpdateAccountMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  member?: Maybe<Account>;
};

/** Autogenerated return type of UpdateAccount. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type UpdateCheckListAttributes = {
  jobDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCheckList */
export type UpdateCheckListInput = {
  attributes: UpdateCheckListAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCheckList. */
export type UpdateCheckListPayload = {
  __typename?: 'UpdateCheckListPayload';
  checkList?: Maybe<CheckList>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateCheckRequest */
export type UpdateCheckRequestInput = {
  attributes: CheckRequestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCheckRequest. */
export type UpdateCheckRequestPayload = {
  __typename?: 'UpdateCheckRequestPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDepartment */
export type UpdateDepartmentInput = {
  attributes: DepartmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDepartment. */
export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Department>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocument */
export type UpdateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDocument. */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type UpdateMemberAttributes = {
  departmentMemberships: Array<DepartmentMembershipAttributes>;
  role: TeamRoleEnum;
};

export type UpdatePaymentMethodAttributes = {
  address?: InputMaybe<AddressAttributes>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderReference?: InputMaybe<Scalars['String']['input']>;
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdatePaymentMethod */
export type UpdatePaymentMethodInput = {
  attributes: UpdatePaymentMethodAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePaymentMethod. */
export type UpdatePaymentMethodPayload = {
  __typename?: 'UpdatePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  tierId: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSubscription. */
export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type UpdateUserAttributes = {
  marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UpdateUserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateWbHandler */
export type UpdateWbHandlerInput = {
  attributes: WbHandlerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWbHandler. */
export type UpdateWbHandlerPayload = {
  __typename?: 'UpdateWbHandlerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbHandler?: Maybe<WbHandler>;
};

/** Autogenerated input type of UpdateWbReport */
export type UpdateWbReportInput = {
  attributes: WbReportAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateWbReport. */
export type UpdateWbReportPayload = {
  __typename?: 'UpdateWbReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  wbReport?: Maybe<WbReport>;
};

/** Autogenerated input type of UploadCheckLists */
export type UploadCheckListsInput = {
  checkRequestId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
};

/** Autogenerated return type of UploadCheckLists. */
export type UploadCheckListsPayload = {
  __typename?: 'UploadCheckListsPayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UploadCheckRequestFile */
export type UploadCheckRequestFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Base64AttachmentAttributes;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UploadCheckRequestFile. */
export type UploadCheckRequestFilePayload = {
  __typename?: 'UploadCheckRequestFilePayload';
  checkRequest?: Maybe<CheckRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UploadSignatures */
export type UploadSignaturesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  file: Base64AttachmentAttributes;
};

/** Autogenerated return type of UploadSignatures. */
export type UploadSignaturesPayload = {
  __typename?: 'UploadSignaturesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of UseSessionOtp */
export type UseSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of UseSessionOtp. */
export type UseSessionOtpPayload = {
  __typename?: 'UseSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deepLink?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']['output']>;
};

export type User = Node & {
  __typename?: 'User';
  confirmed: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']['output']>;
  marketingConsent: Scalars['Boolean']['output'];
  necessaryCookiesConsent: Scalars['Boolean']['output'];
  occupation?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ValidatePasswordResetToken */
export type ValidatePasswordResetTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailField']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ValidatePasswordResetToken. */
export type ValidatePasswordResetTokenPayload = {
  __typename?: 'ValidatePasswordResetTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ValidateSessionTfa */
export type ValidateSessionTfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sessionToken: Scalars['String']['input'];
  tfaCode: Scalars['String']['input'];
};

/** Autogenerated return type of ValidateSessionTfa. */
export type ValidateSessionTfaPayload = {
  __typename?: 'ValidateSessionTfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type VeriffSession = Node & {
  __typename?: 'VeriffSession';
  /** ID of the object. */
  id: Scalars['ID']['output'];
  sessionUrl: Scalars['String']['output'];
};

/** Autogenerated input type of VerifyPhoneNumber */
export type VerifyPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPhoneNumber. */
export type VerifyPhoneNumberPayload = {
  __typename?: 'VerifyPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
};

export type WbHandler = Node & {
  __typename?: 'WbHandler';
  account: Account;
  default: Scalars['Boolean']['output'];
  hasReports: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type WbHandlerAttributes = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WbHandlerPagination = {
  __typename?: 'WbHandlerPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<WbHandler>;
  total: Scalars['Int']['output'];
};

export type WbMessage = Node & {
  __typename?: 'WbMessage';
  addedHandlers?: Maybe<Array<PublicWbHandler>>;
  content?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  internal: Scalars['Boolean']['output'];
  newCategory?: Maybe<WbReportCategoryEnum>;
  newType?: Maybe<WbReportTypeEnum>;
  oldCategory?: Maybe<WbReportCategoryEnum>;
  oldType?: Maybe<WbReportTypeEnum>;
  removedHandlers?: Maybe<Array<PublicWbHandler>>;
  seen: Scalars['Boolean']['output'];
  sentAt: Scalars['String']['output'];
  type: WbMessageTypeEnum;
  wbHandler?: Maybe<PublicWbHandler>;
};

export type WbMessageAttributes = {
  content?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Base64AttachmentAttributes>>;
  internal: Scalars['Boolean']['input'];
};

export enum WbMessageTypeEnum {
  CategoryChanged = 'CATEGORY_CHANGED',
  HandlersChanged = 'HANDLERS_CHANGED',
  Message = 'MESSAGE',
  ReportClosed = 'REPORT_CLOSED',
  ReportDeleted = 'REPORT_DELETED',
  ReportReopened = 'REPORT_REOPENED',
  TypeChanged = 'TYPE_CHANGED'
}

export type WbReport = Node & {
  __typename?: 'WbReport';
  abandoned: Scalars['Boolean']['output'];
  action?: Maybe<Scalars['String']['output']>;
  category?: Maybe<WbReportCategoryEnum>;
  closedAt?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadPath?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  files: Array<Attachment>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  isAccessible: Scalars['Boolean']['output'];
  lastInteractionAt: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumberStruct>;
  privacy: WbReportPrivacyEnum;
  reportNumber?: Maybe<Scalars['String']['output']>;
  routineGuidelines: Array<Attachment>;
  status: WbReportStatusEnum;
  submittedAt?: Maybe<Scalars['String']['output']>;
  timing?: Maybe<Scalars['String']['output']>;
  type: WbReportTypeEnum;
  wbHandlers: Array<PublicWbHandler>;
  wbMessages: Array<WbMessage>;
  wbMessagesCount: Scalars['Int']['output'];
};

export type WbReportAttributes = {
  category?: InputMaybe<WbReportCategoryEnum>;
  type?: InputMaybe<WbReportTypeEnum>;
  wbHandlerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum WbReportCategoryEnum {
  BriberyCorruptionConflictOfInterest = 'BRIBERY_CORRUPTION_CONFLICT_OF_INTEREST',
  BullyingHarassmentThreatsViolence = 'BULLYING_HARASSMENT_THREATS_VIOLENCE',
  DangerousPhysicalConditions = 'DANGEROUS_PHYSICAL_CONDITIONS',
  DrugOrDopingAbuse = 'DRUG_OR_DOPING_ABUSE',
  FinancialMisconductEmbezzlementTheft = 'FINANCIAL_MISCONDUCT_EMBEZZLEMENT_THEFT',
  MishandlingOfPersonalDataDataLeaks = 'MISHANDLING_OF_PERSONAL_DATA_DATA_LEAKS',
  Other = 'OTHER',
  PollutionViolationOfEnvironmentalLegislation = 'POLLUTION_VIOLATION_OF_ENVIRONMENTAL_LEGISLATION',
  RacismAndDiscrimination = 'RACISM_AND_DISCRIMINATION',
  SexualHarassmentAndAbuse = 'SEXUAL_HARASSMENT_AND_ABUSE'
}

export type WbReportPagination = {
  __typename?: 'WbReportPagination';
  exportPath?: Maybe<Scalars['String']['output']>;
  items: Array<WbReport>;
  total: Scalars['Int']['output'];
};

export enum WbReportPrivacyEnum {
  Anonymous = 'ANONYMOUS',
  Confidential = 'CONFIDENTIAL'
}

export enum WbReportStatusEnum {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED'
}

export enum WbReportTypeEnum {
  Feedback = 'FEEDBACK',
  Whistleblowing = 'WHISTLEBLOWING'
}

export type WbReportsFilter = {
  category?: InputMaybe<WbReportCategoryEnum>;
  reportNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WbReportStatusEnum>;
  type?: InputMaybe<WbReportTypeEnum>;
};

export enum WbReportsSortByEnum {
  LastInteractionAtAsc = 'LAST_INTERACTION_AT_ASC',
  LastInteractionAtDesc = 'LAST_INTERACTION_AT_DESC',
  ReportNumberAsc = 'REPORT_NUMBER_ASC',
  ReportNumberDesc = 'REPORT_NUMBER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC'
}

export type WbSettings = {
  __typename?: 'WbSettings';
  description?: Maybe<Scalars['String']['output']>;
  feedbackDescription?: Maybe<Scalars['String']['output']>;
  feedbackEnabled: Scalars['Boolean']['output'];
  feedbackLink?: Maybe<Scalars['String']['output']>;
  feedbackReadMoreLink?: Maybe<Scalars['String']['output']>;
  whistleblowingDescription?: Maybe<Scalars['String']['output']>;
  whistleblowingReadMoreLink?: Maybe<Scalars['String']['output']>;
};

export type WbSettingsAttributes = {
  description?: InputMaybe<Scalars['String']['input']>;
  feedbackDescription?: InputMaybe<Scalars['String']['input']>;
  feedbackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  feedbackLink?: InputMaybe<Scalars['String']['input']>;
  feedbackReadMoreLink?: InputMaybe<Scalars['String']['input']>;
  whistleblowingDescription?: InputMaybe<Scalars['String']['input']>;
  whistleblowingReadMoreLink?: InputMaybe<Scalars['String']['input']>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  company: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  finishMonth?: Maybe<Scalars['String']['output']>;
  hrEmail?: Maybe<Scalars['String']['output']>;
  managerEmail?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
  startMonth: Scalars['String']['output'];
};

export type WorkExperienceAttributes = {
  company: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  current: Scalars['Boolean']['input'];
  finishMonth?: InputMaybe<Scalars['String']['input']>;
  hrEmail?: InputMaybe<Scalars['String']['input']>;
  managerEmail?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
  startMonth: Scalars['String']['input'];
};

export type WorkExperienceCheck = {
  __typename?: 'WorkExperienceCheck';
  files?: Maybe<Array<Attachment>>;
  workExperiences?: Maybe<Array<WorkExperience>>;
};

export type WorkExperienceCheckAttributes = {
  workExperiences: Array<WorkExperienceAttributes>;
};
