import { SessionFragment } from "graphql/fragments";

export default (session: SessionFragment): string => {
  const identity = session.account.defaultIdentity;
  const name = identity
    ? `${identity.firstName} ${identity.lastName}`
    : (session.account.name ?? "-");

  return name;
};
