import { gql } from "@apollo/client";

import { EducationCheck } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const EDUCATION_FRAGMENT = gql`
  fragment EducationFragment on EducationCheck {
    explanation
    degree
    institution
    country
    startMonth
    finishMonth
    files {
      ...AttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface EducationFragment
  extends Pick<
    EducationCheck,
    | "explanation"
    | "degree"
    | "institution"
    | "country"
    | "startMonth"
    | "finishMonth"
  > {
  files?: AttachmentFragment[];
}
