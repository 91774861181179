import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LanguageEnum } from "graphql/types";
import { IntlContext, getIsValidLanguage } from "providers/i18n";
import { getLocalePrefix, useRoutePaths } from "utils";

export default (): null => {
  const { currentLocale, setLocale } = useContext(IntlContext);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const getRoutePath = useRoutePaths();

  useEffect(() => {
    const [_, version, locale] = pathname.split("/");

    // Redirect outdated routes starting with locale
    if (getIsValidLanguage(version)) {
      const lang = version;

      // Redirect outdated email routes
      if (pathname.includes("public-document"))
        return navigate(
          `/v2/${lang}/signmore/public-document/${
            pathname.split("public-document/")[1]
          }`
        );

      if (pathname.includes("sign-document"))
        return navigate(
          `/v2/${lang}/signmore/sign-document/${
            pathname.split("sign-document/")[1]
          }`
        );

      if (pathname.includes("login/magic-link/verification"))
        return navigate(`/v2/${lang}/login/magic-link/verification${search}`);
    }

    // Redirect public payoutpartner routes
    if (pathname.includes("public-invoice/"))
      return navigate(
        getRoutePath({ module: "common", routeName: "PAYOUTPARTNER" })
      );

    // Redirect invalid routes
    const isV2 = version === "v2";
    const isValidLocale = getIsValidLanguage(locale);
    if (!isV2 || !isValidLocale)
      return navigate(
        "/v2" +
          getLocalePrefix(currentLocale) +
          pathname.split(locale)[1] +
          search
      );

    // Update locale from pathname
    const validLocale = locale?.toUpperCase() as LanguageEnum;
    if (currentLocale !== validLocale) setLocale(validLocale);
  }, [pathname]);

  return null;
};
