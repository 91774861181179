import { gql } from "@apollo/client";

import {
  PepRelationAttributes,
  SpecificSourceSearchCheckAttributes,
} from "graphql/types";

export const SPECIFIC_SOURCE_SEARCH_FRAGMENT = gql`
  fragment SpecificSourceSearchFragment on SpecificSourceSearchCheck {
    ambassador
    auditorBanker
    country
    headMinister
    internationalOrganization
    judge
    militaryOfficer
    nationalAssembly
    organization
    pep
    pepRelation
    pepRelations {
      address
      country
      dateOfBirth
      gender
      identifier
      identifierUnavailable
      name
      organization
      placeOfBirth
      terminatedOn
      title
      type
    }
    politicalParty
    stateEnterprise
    terminatedOn
    title
  }
`;

export interface SpecificSourceSearchFragment
  extends Pick<
    SpecificSourceSearchCheckAttributes,
    | "ambassador"
    | "auditorBanker"
    | "country"
    | "headMinister"
    | "internationalOrganization"
    | "judge"
    | "militaryOfficer"
    | "nationalAssembly"
    | "organization"
    | "pep"
    | "pepRelation"
    | "politicalParty"
    | "stateEnterprise"
    | "terminatedOn"
    | "title"
  > {
  pepRelations?: Pick<
    PepRelationAttributes,
    | "address"
    | "country"
    | "dateOfBirth"
    | "gender"
    | "identifier"
    | "identifierUnavailable"
    | "name"
    | "organization"
    | "placeOfBirth"
    | "terminatedOn"
    | "title"
    | "type"
  >[];
}
