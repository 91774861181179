import { gql } from "@apollo/client";

import { SocialMediaAccountAttributes } from "graphql/types";

export const OPEN_SOURCE_SEARCH_FRAGMENT = gql`
  fragment OpenSourceSearchFragment on OpenSourceSearchCheck {
    socialMediaAccounts {
      platform
      url
    }
  }
`;

export interface OpenSourceSearchFragment {
  socialMediaAccounts: Pick<SocialMediaAccountAttributes, "platform" | "url">[];
}
