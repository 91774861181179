import { RouteProps } from "react-router-dom";

import { Home } from "components/app";
import {
  CriiptoCallback,
  GateKeeper,
  PayoutPartnerInfo,
  Privacy,
  Terms,
} from "components/common/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("common","HOME"), element: <Home /> },
    { path: getRoutePath("common","PRIVACY"), element: <Privacy /> },
    { path: getRoutePath("common","TERMS"), element: <Terms /> },
    { path: getRoutePath("common","CRIIPTO_CALLBACK"), element: <CriiptoCallback /> },
    { path: getRoutePath("common","PAYOUTPARTNER"), element: <PayoutPartnerInfo /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("common","GATE_KEEPER"), element: <GateKeeper /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "common", routes, loggedInRoutes, loggedOutRoutes };
};
