import React from "react";
import { useEffect, useState } from "react";
import { HiCheck, HiX } from "react-icons/hi";

import { Spinner } from "components/common/basic";
import { tw } from "utils/tw";

interface Props {
  steps: string[];
  stepErrorIndex?: number;
  loadingTime?: number;
  setIsCompleted?: (t: boolean) => void;
  isLoading: boolean;
}

export default ({
  steps,
  stepErrorIndex = -1,
  loadingTime = 500,
  setIsCompleted,
  isLoading,
}: Props) => {
  const circleStyles = tw(
    "h-8",
    "w-8",
    "rounded-full",
    "text-white",
    "flex",
    "items-center",
    "justify-center"
  );

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (currentStep > steps.length - 1) return;

    if (!isLoading) {
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
      }, loadingTime);
    }
  }, [currentStep, isLoading]);

  useEffect(() => {
    if (!setIsCompleted) return;
    const isLastStepLoaded = currentStep === steps.length;
    if (isLastStepLoaded) setIsCompleted(true);
  }, [steps, currentStep]);

  return (
    <div className={tw("flex", "flex-col")}>
      <div className={tw("w-80")}>
        {steps.map((step, index) => {
          const isError = stepErrorIndex !== -1 && stepErrorIndex <= index;
          const isLoading = currentStep <= index;

          const lineColor = tw(
            isError
              ? "border-deepBlue-100"
              : isError
                ? "border-error"
                : "border-teal-900"
          );

          return (
            <React.Fragment key={index}>
              {index !== 0 && (
                <div
                  className={tw(lineColor, "h-8", "border-l-2", "ml-4")}
                ></div>
              )}
              <div className={tw("flex", "items-center")}>
                <div className={tw("mr-2")}>
                  {isLoading ? (
                    <Spinner size="sm" withoutText data-testid="spinner" />
                  ) : isError ? (
                    <div
                      data-testid="hi-x-icon"
                      className={tw(circleStyles, "bg-error")}
                    >
                      <HiX />
                    </div>
                  ) : (
                    <div
                      data-testid="hi-check-icon"
                      className={tw(circleStyles, "bg-teal-900")}
                    >
                      <HiCheck />
                    </div>
                  )}
                </div>
                <p className={tw("text-sm")}>{step}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
