import { useContext } from "react";

import {
  PublicWbAccountFragment,
  SimplifiedAccountFragment,
} from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { getNameFromAccount } from "utils";
import { logo } from "utils/brand";
import { tw } from "utils/tw";

interface Props {
  variant: "badge" | "manymore" | "sidemenu";
  account?: SimplifiedAccountFragment | PublicWbAccountFragment;
}

export default ({ variant, ...props }: Props) => {
  const { session } = useContext(AuthContext);
  const account = props.account ?? session?.account;

  if (!account) return null;

  return (
    <div
      className={tw(
        "shrink-0",
        "inline-flex",
        "items-center",
        "overflow-hidden",
        {
          [tw("bg-white", "p-2", "rounded-md", "space-x-3")]:
            variant === "badge",
          [tw("space-x-3")]: variant === "sidemenu",
          [tw("space-x-6")]: variant === "manymore",
        }
      )}
    >
      <img
        src={`${process.env.REACT_APP_API_URL}${account.symbolDisplayPath}`}
        alt="logo"
        className={tw("rounded-full", "object-cover", {
          [tw("w-6", "h-6")]: variant === "badge",
          [tw("w-8", "h-8")]: variant === "sidemenu",
          [tw("w-12", "h-12")]: variant === "manymore",
        })}
      />

      {variant === "manymore" ? (
        <>
          <span className={tw("w-px", "h-full", "bg-deepBlue-100")} />

          <img className={tw("h-6")} src={logo} alt="logo" />
        </>
      ) : (
        <p className={tw("text-deepBlue-700", "font-semibold")}>
          {getNameFromAccount(account)}
        </p>
      )}
    </div>
  );
};
