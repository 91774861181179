import { gql } from "@apollo/client";

import { Tier } from "graphql/types";

import { PRODUCT_FRAGMENT, ProductFragment } from "./product";

export const TIER_FRAGMENT = gql`
  fragment TierFragment on Tier {
    id
    type
    amount
    currency
    interval
    memberLimit
    memberUsage
    features
    products {
      docuCheck {
        ...ProductFragment
      }
      eSignature {
        ...ProductFragment
      }
      nordicIdCheck {
        ...ProductFragment
      }
      norskPolitiattest {
        ...ProductFragment
      }
      basicCheck {
        ...ProductFragment
      }
      basicPlusCheck {
        ...ProductFragment
      }
      enhancedCheck {
        ...ProductFragment
      }
      comprehensiveCheck {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export interface TierFragment
  extends Pick<
    Tier,
    | "id"
    | "type"
    | "amount"
    | "currency"
    | "interval"
    | "memberLimit"
    | "memberUsage"
    | "features"
    | "products"
  > {
  products: {
    docuCheck: ProductFragment;
    eSignature: ProductFragment;
    nordicIdCheck: ProductFragment;
    norskPolitiattest: ProductFragment;
    basicCheck: ProductFragment;
    basicPlusCheck: ProductFragment;
    enhancedCheck: ProductFragment;
    comprehensiveCheck: ProductFragment;
  };
}
