import { useContext } from "react";
import { useController, useForm } from "react-hook-form";
import { IconType } from "react-icons";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Link, RadioButton } from "components/common/basic";
import { FileUpload } from "components/common/composite";
import { PopupWrapper } from "components/common/popups";
import {
  useCreateCheckListsMutation,
  useUploadCheckListsMutation,
} from "graphql/mutations";
import { CheckRequestTypeEnum, FeatureTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { emailValidationSchema } from "utils";
import { tw } from "utils/tw";

import csvUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";

import PeopleInputTable, { FormWithPeopleInputTable } from "./PeopleInputTable";

export enum AdditionMethod {
  manual = "MANUAL",
  csv = "CSV",
}

interface FormValues extends FormWithPeopleInputTable {
  method?: AdditionMethod;
}

interface IconBase {
  type: "ICON" | "IMAGE";
}
interface IconTypeIcon extends IconBase {
  type: "ICON";
  Icon: IconType;
}
interface ImageTypeIcon extends IconBase {
  type: "IMAGE";
  alt: string;
  src: string;
}
export type AddPeopleIcon = IconTypeIcon | ImageTypeIcon;

interface Props {
  checkRequestID: string;
  title: string;
  hasPrevious: boolean;
  checkRequestType: CheckRequestTypeEnum;
  onSuccess?: () => void;
  availableMethods?: AdditionMethod[];
  icon?: AddPeopleIcon;
}

export default ({
  checkRequestID,
  onSuccess,
  availableMethods = [AdditionMethod.manual, AdditionMethod.csv],
  icon,
  checkRequestType,
  ...props
}: Props): JSX.Element => {
  const { closeAllPopups, openPopup } = useContext(PopupContext);
  const { createCheckLists } = useCreateCheckListsMutation();
  const { uploadCheckLists } = useUploadCheckListsMutation();

  const { session } = useContext(AuthContext);
  const { isMd } = useContext(TailwindContext);
  const { t } = useTranslation("common");
  const formId = "add-people-form";

  const peopleErrors = {
    lastName: t(
      "popup.addPeople.error.firstName.required",
      "Please add the first name"
    ),
    firstName: t(
      "popup.addPeople.error.lastName.required",
      "Please add the last name"
    ),
    role: t("popup.addPeople.error.role.required", "Please add the role"),
    type: t("popup.addPeople.error.type.required", "Please select a job type"),
    departments: t(
      "popup.addPeople.error.departments.required",
      "Please select a department"
    ),
    employmentDate: t(
      "popup.addPeople.error.employmentDate.required",
      "Please select the start date of the employment"
    ),
    employed: t(
      "popup.addPeople.error.employed.required",
      "Please select an employment status"
    ),
  };

  const validationSchema = Yup.object({
    method: Yup.string<AdditionMethod>().oneOf(availableMethods),
    people: Yup.array().of(
      Yup.object({
        email: emailValidationSchema(t).required(),
        firstName: Yup.string().required(peopleErrors.firstName),
        lastName: Yup.string().required(peopleErrors.lastName),
        departments: Yup.string().required(peopleErrors.departments),
        ...(checkRequestType === CheckRequestTypeEnum.CriminalRecord
          ? {
              role: Yup.string().required(peopleErrors.role),
              phone: Yup.string(),
              type: Yup.string<"permanent" | "temporary">()
                .required(peopleErrors.type)
                .oneOf(["permanent", "temporary"], peopleErrors.type),
              employmentDate: Yup.string().required(
                peopleErrors.employmentDate
              ),
              employed: Yup.string<"yes" | "no">()
                .required(peopleErrors.employed)
                .oneOf(["yes", "no"], peopleErrors.employed),
            }
          : {
              role: Yup.string(),
              phone: Yup.string(),
              type: Yup.string<"permanent" | "temporary">(),
              employmentDate: Yup.string(),
              employed: Yup.string<"yes" | "no">(),
            }),
      })
    ),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: { method: availableMethods[0] },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const { field: methodField } = useController({ name: "method", control });
  const method = watch("method");

  const onSubmit = ({ method, people }: FormValues) => {
    if (method !== AdditionMethod.manual) return;
    if (!people) return;

    const jobDescription =
      "Person skal utføre oppgaver for organisasjonen som innebærer et tillits- eller ansvarsforhold overfor mindreåringe.";

    createCheckLists(
      {
        id: checkRequestID,
        attributes: people.map((person) => ({
          email: person.email ?? "",
          firstName: person.firstName ?? "",
          lastName: person.lastName ?? "",
          phone: person.phone,
          departmentId: person.departments,
          criminalRecordAttributes:
            checkRequestType === CheckRequestTypeEnum.CriminalRecord
              ? {
                  jobDescription,
                  jobRole: person.role ?? "",
                  temporaryJob: person.type === "temporary",
                  employedOn: person.employmentDate ?? "",
                  alreadyEmployed: person.employed === "yes",
                }
              : undefined,
        })),
      },
      () => {
        onSuccess?.();

        closeAllPopups();
      }
    );
  };

  const uploadFile = (files: File[]) => {
    if (files.length < 1) return;
    const file = files[0];

    const reader = new FileReader();

    reader.onload = (event) => {
      if (typeof event.target?.result !== "string") return;

      uploadCheckLists(
        { id: checkRequestID, file: { data: event.target.result } },
        () => {
          onSuccess?.();

          closeAllPopups();
        }
      );
    };

    reader.readAsDataURL(file);
  };

  const hasMultipleAdditionMethods = availableMethods.length > 1;
  const hasImportFeature = (
    session?.account.subscription?.tier.features ?? []
  ).includes(FeatureTypeEnum.Import);

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      popupSize="lg"
      footerActions={[
        {
          id: "add_people-add_people",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.addPeople.submit", "Add"),
        },
      ]}
    >
      {hasMultipleAdditionMethods && isMd && (
        <div className={tw("flex", "space-x-4", "mb-8")}>
          {availableMethods.includes(AdditionMethod.manual) && (
            <RadioButton
              id="method.manual"
              name={methodField.name}
              onChange={methodField.onChange}
              checked={methodField.value === AdditionMethod.manual}
              value={AdditionMethod.manual}
              label={t("popup.addPeople.method.manual", "Manually")}
            />
          )}

          {availableMethods.includes(AdditionMethod.csv) && (
            <RadioButton
              id="method.csv"
              name={methodField.name}
              onChange={
                hasImportFeature
                  ? methodField.onChange
                  : () =>
                      openPopup(
                        {
                          id: "UpgradeRequired",
                          props: { feature: FeatureTypeEnum.Import },
                        },
                        true
                      )
              }
              checked={methodField.value === AdditionMethod.csv}
              value={AdditionMethod.csv}
              label={t("popup.addPeople.method.csv", "Import (CSV upload)")}
            />
          )}
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={tw("w-full", "space-y-4")}
        id={formId}
      >
        <input {...register("method")} style={{ display: "none" }} />

        {method === AdditionMethod.manual && (
          <PeopleInputTable
            control={control}
            register={register}
            peopleErrors={errors.people}
            clearErrors={clearErrors}
            checkRequestType={checkRequestType}
          />
        )}

        {method === AdditionMethod.csv && (
          <div className={tw("space-y-6")}>
            <Link
              id="add_people-go_to_csv_help"
              isExternal
              to={t(
                "popup.addPeople.uploadCSV.goToHelp.link",
                "https://en.support.manymore.com"
              )}
              unstyled
            >
              <Button
                id="add_people-go_to_csv_help"
                size="md"
                variant="secondary"
              >
                {t(
                  "popup.addPeople.uploadCSV.goToHelp.label",
                  "Read more and download Excel-template"
                )}
              </Button>
            </Link>

            <FileUpload
              id="csv-upload"
              selectFile={uploadFile}
              clearFile={() => null}
              illustration={{
                src: csvUploadIllustration,
                alt: t(
                  "popup.addPeople.uploadCSV.illustration.alt",
                  "File attachment"
                ),
              }}
              validFileTypes={[
                "text/csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
              fileRequirementsText={t(
                "popup.addPeople.uploadCSV.validFileTypes",
                "Files with .csv extension"
              )}
            />
          </div>
        )}
      </form>
    </PopupWrapper>
  );
};
