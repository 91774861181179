import { Icon, IconType } from "components/common/basic";
import { CheckStatusEnum, CheckTypeEnum } from "graphql/types";

interface Props {
  type: CheckTypeEnum;
  status: CheckStatusEnum;
}

export default ({ type, status }: Props): JSX.Element => {
  const badgeColor = (() => {
    switch (status) {
      case CheckStatusEnum.Completed:
        return "text-success";

      case CheckStatusEnum.CompletedWithRemarks:
        return "text-badge-warning";

      case CheckStatusEnum.PendingAdmin:
      case CheckStatusEnum.PendingCandidate:
      case CheckStatusEnum.PendingExternal:
        return "text-blue-500";

      default:
        return ((_status: never) => "text-deepBlue-50" as const)(status);
    }
  })();

  const badgeIcon: IconType = (() => {
    switch (type) {
      case CheckTypeEnum.BankId:
      case CheckTypeEnum.Identity:
      case CheckTypeEnum.PersonalNumber:
      case CheckTypeEnum.GlobalIdentity:
        return "HiIdentification";

      case CheckTypeEnum.Information:
        return "HiDocumentText";

      case CheckTypeEnum.CriminalRecord:
        return "HiShieldCheck";

      case CheckTypeEnum.ELearning:
        return "HiAcademicCap";

      case CheckTypeEnum.WorkExperience:
        return "HiBriefcase";

      case CheckTypeEnum.Education:
        return "HiAcademicCap";

      case CheckTypeEnum.OpenSourceSearch:
        return "HiShare";

      case CheckTypeEnum.SpecificSourceSearch:
        return "HiSearchCircle";

      case CheckTypeEnum.RelatedCountries:
        return "HiGlobe";

      case CheckTypeEnum.BusinessInterests:
        return "HiOfficeBuilding";

      case CheckTypeEnum.PersonalCredit:
        return "HiCreditCard";

      case CheckTypeEnum.Bankruptcy:
        return "HiCurrencyDollar";

      case CheckTypeEnum.Address:
        return "HiLocationMarker";

      case CheckTypeEnum.LegalCaseRecords:
        return "HiScale";

      // TODO: Assign correct icons
      case CheckTypeEnum.Certificates:
      case CheckTypeEnum.WorkPermit:
        return "HiStar";

      default:
        return ((_type: never) => "HiStar" as const)(type);
    }
  })();

  return <Icon size={20} color={badgeColor} icon={badgeIcon} />;
};
