/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  CHECK_LIST_FRAGMENT,
  CheckListFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CHECK_REQUEST, CheckRequestQuery } from "graphql/queries";
import { UpdateCheckListAttributes } from "graphql/types";

const UPDATE_CHECK_LIST = gql`
  mutation ($id: ID!, $attributes: UpdateCheckListAttributes!) {
    updateCheckList(input: { id: $id, attributes: $attributes }) {
      checkList {
        ...CheckListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkList: CheckListFragment;
  errors: ErrorFragment[];
}

interface UpdateCheckListMutation {
  updateCheckList: Payload | null;
}

export const useUpdateCheckListMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateCheckListMutation,
    { id: string; attributes: UpdateCheckListAttributes }
  >(UPDATE_CHECK_LIST);

  const updateCheckList = (
    variables: { id: string; attributes: UpdateCheckListAttributes },
    onSuccess?: () => void
  ) =>
    mutation({
      variables,
      update(cache, { data }) {
        cache.modify({
          fields: {
            session() {
              const updatedDescription =
                data?.updateCheckList?.checkList.criminalRecordAttributes
                  ?.jobDescription;
              if (!updatedDescription) return;

              const checkRequestQuery = cache.readQuery<CheckRequestQuery>({
                query: CHECK_REQUEST,
              });
              if (!checkRequestQuery) return;

              const checkLists = checkRequestQuery.node.checkLists.map(
                (checkList) =>
                  checkList.id === variables.id &&
                  checkList.criminalRecordAttributes
                    ? {
                        ...checkList,
                        criminalRecordAttributes: {
                          ...checkList.criminalRecordAttributes,
                          jobDescription: updatedDescription,
                        },
                      }
                    : checkList
              );

              cache.writeQuery<CheckRequestQuery>({
                query: CHECK_REQUEST,
                data: {
                  node: {
                    ...checkRequestQuery.node,
                    checkLists,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateCheckList?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateCheckList,
    isLoading: loading,
    errors: data?.updateCheckList?.errors ?? [],
  };
};
