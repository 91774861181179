import { Button, Link } from "components/common/basic";
import { TierFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { gTag, useRoutePaths } from "utils";

interface Props {
  tier: TierFragment;
  currentTier?: TierFragment;
  onSelect?: (tier: TierFragment) => void;
}
export default ({ tier, currentTier, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation("login");
  const getRoutePath = useRoutePaths();
  const getSignupLink = (id: string) =>
    getRoutePath({
      module: "login",
      routeName: "SIGNUP_TEAM",
      arg1: id,
    });

  const isCurrent = currentTier?.id === tier.id;
  const isIntervalChange =
    currentTier?.amount !== 0 && currentTier?.interval !== tier.interval;
  const isUpgrade = currentTier && tier.amount > currentTier.amount;
  const label = currentTier
    ? (() => {
        if (isCurrent)
          return t("planSelection.plan_current.selectPlan", "Current plan");

        if (isIntervalChange)
          return t("planSelection.plan_intervalChange.selectPlan", "Select");

        if (isUpgrade)
          return t("planSelection.plan_upgrade.selectPlan", "Upgrade");

        return t("planSelection.plan_downgrade.selectPlan", "Downgrade");
      })()
    : (() => {
        switch (tier.amount) {
          case -1:
            return t(
              "planSelection.plan_enterprise.selectPlan",
              "Contact sales"
            );

          case 0:
            return t("planSelection.plan_free.selectPlan", "Sign up");

          default:
            return t("planSelection.plan.selectPlan", "Select");
        }
      })();

  const variant = currentTier
    ? isUpgrade
      ? "primary"
      : "tertiary"
    : tier.amount > 0
      ? "primary"
      : "secondary";

  const button = (
    <Button
      id="tier_selection-select_plan"
      variant={variant}
      fullWidth
      onClick={
        onSelect
          ? () => onSelect(tier)
          : tier.amount === -1
            ? () => gTag("conversion_event_contact")
            : undefined
      }
      disabled={isCurrent}
    >
      {label}
    </Button>
  );
  const action = onSelect ? (
    button
  ) : (
    <Link
      id="tier_selection-select_plan"
      to={
        tier.amount === -1
          ? t("planSelection.plan_enterprise.link", "mailto:sales@manymore.com")
          : getSignupLink(tier.id)
      }
      isExternal={tier.amount === -1}
      unstyled
    >
      {button}
    </Link>
  );

  return action;
};
