import { Badge } from "components/common/basic";
import { CheckStatusEnum } from "graphql/types";
import { useTranslation } from "translations";
import { TTextColor } from "tw-generated";
import {
  snakeCaseToCamelCase,
  snakeCaseToSeparated,
} from "utils/convertSnakeCase";
import { tw } from "utils/tw";

interface Props {
  variant: "text" | "tag";
  viewer: "sender" | "receiver";
  status: CheckStatusEnum;
  showStatus?: boolean;
  message?: string;
  badgeSize?: "sm" | "lg";
  textColor?: TTextColor;
}

export default ({
  variant,
  viewer,
  status,
  message,
  badgeSize = "lg",
  textColor = "text-deepBlue-900",
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const isSender = viewer === "sender";

  const statusMessage =
    message ??
    t(
      `checkStatusMessage_${isSender ? "sender" : "receiver"}.${snakeCaseToCamelCase(status)}`,
      snakeCaseToSeparated(status)
    );

  const badgeVariant = (() => {
    switch (status) {
      case CheckStatusEnum.Completed:
        return "success";

      case CheckStatusEnum.CompletedWithRemarks:
        return "warning";

      case CheckStatusEnum.PendingAdmin:
      case CheckStatusEnum.PendingCandidate:
      case CheckStatusEnum.PendingExternal:
        return "pending";

      default:
        return ((_status: never) => "default" as const)(status);
    }
  })();

  if (variant === "text")
    return (
      <p
        className={tw("uppercase", "font-bold", textColor, {
          "text-xs": badgeSize === "sm",
        })}
      >
        {statusMessage}
      </p>
    );

  return <Badge size={badgeSize} variant={badgeVariant} text={statusMessage} />;
};
