import { TFunction } from "i18next";

import { CheckRequestTypeEnum, CheckTypeEnum } from "graphql/types";

export const getTitleFromCheckRequestType = (
  type: CheckRequestTypeEnum,
  t: TFunction,
  isShortVersion = false
): string => {
  switch (type) {
    case CheckRequestTypeEnum.CriminalRecord:
      return isShortVersion
        ? t(
            "common:checkRequestType_criminalRecord.title_short",
            "Criminal record"
          )
        : t(
            "common:checkRequestType_criminalRecord.title",
            "Norwegian Criminal record certificate"
          );
    case CheckRequestTypeEnum.Identity:
      return isShortVersion
        ? t(
            "common:checkRequestType_identity.title_short",
            "Identity verification"
          )
        : t(
            "common:checkRequestType_identity.title",
            "Nordic identity verification"
          );
    case CheckRequestTypeEnum.Information:
      return isShortVersion
        ? t(
            "common:checkRequestType_documentConfirmation.title_short",
            "DocuCheck"
          )
        : t(
            "common:checkRequestType_documentConfirmation.title",
            "DocuCheck: read confirmation of documents"
          );

    case CheckRequestTypeEnum.Basic:
      return isShortVersion
        ? t("common:checkRequestType_basic.title_short", "Basic")
        : t("common:checkRequestType_basic.title", "Basic");

    case CheckRequestTypeEnum.BasicPlus:
      return isShortVersion
        ? t("common:checkRequestType_basicPlus.title_short", "Basic Plus")
        : t("common:checkRequestType_basicPlus.title", "Basic Plus");

    case CheckRequestTypeEnum.Enhanced:
      return isShortVersion
        ? t("common:checkRequestType_enhanced.title_short", "Enhanced")
        : t("common:checkRequestType_enhanced.title", "Enhanced");
    case CheckRequestTypeEnum.Comprehensive:
      return isShortVersion
        ? t(
            "common:checkRequestType_comprehensive.title_short",
            "Comprehensive"
          )
        : t("common:checkRequestType_comprehensive.title", "Comprehensive");

    default:
      return ((_type: never) => "")(type);
  }
};

export const getTitleFromCheckType = (
  type: CheckTypeEnum,
  t: TFunction
): string => {
  switch (type) {
    case CheckTypeEnum.BankId:
      return t(
        "common:checkType_bankID.title",
        "Norwegian identity verification"
      );
    case CheckTypeEnum.Identity:
      return t("common:checkType_identity.title", "Identity verification");
    case CheckTypeEnum.Information:
      return t(
        "common:checkType_documentConfirmation.title",
        "Policy Document"
      );
    case CheckTypeEnum.CriminalRecord:
      return t(
        "common:checkType_criminalRecord.title",
        "Criminal record certificate"
      );
    case CheckTypeEnum.ELearning:
      return t("common:checkType_eLearning.title", "ELearning");

    case CheckTypeEnum.PersonalCredit:
      return t("common:checkType_personalCredit.title", "Credit check");
    case CheckTypeEnum.BusinessInterests:
      return t(
        "common:checkType_businessInterests.title",
        "Business interests"
      );
    case CheckTypeEnum.WorkExperience:
      return t(
        "common:checkType_workExperience.title",
        "Employment verification"
      );
    case CheckTypeEnum.Education:
      return t("common:checkType_education.title", "Education verification");
    case CheckTypeEnum.OpenSourceSearch:
      return t("common:checkType_openSourceSearch.title", "Open source search");
    case CheckTypeEnum.SpecificSourceSearch:
      return t(
        "common:checkType_specificSourceSearch.title",
        "Politically exposure information"
      );
    case CheckTypeEnum.PersonalNumber:
      return t("common:checkType_personalNumber.title", "Personal number");
    case CheckTypeEnum.GlobalIdentity:
      return t(
        "common:checkType_globalIdentity.title",
        "Global identity verification"
      );
    case CheckTypeEnum.RelatedCountries:
      return t(
        "common:checkType_relatedCountries.title",
        "Related country check"
      );

    case CheckTypeEnum.Address:
      return t("common:checkType_address.title", "Address check");
    case CheckTypeEnum.Bankruptcy:
      return t("common:checkType_bankruptcy.title", "Bankruptcy check");
    case CheckTypeEnum.Certificates:
      return t(
        "common:checkType_certificates.title",
        "Certificates and courses"
      );
    case CheckTypeEnum.LegalCaseRecords:
      return t("common:checkType_legalCaseRecords.title", "Legal case records");
    case CheckTypeEnum.WorkPermit:
      return t("common:checkType_workPermit.title", "Work permit check");

    default:
      return ((_type: never) => "")(type);
  }
};
