/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { ResendTfaCodeInput } from "graphql/types";

const RESEND_TFA_CODE = gql`
  mutation ($input: ResendTfaCodeInput!) {
    resendTfaCode(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface ResendTfaCodeMutation {
  resendTfaCode: Payload | null;
}

export const useResendTfaCodeMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentTfaCode",
    "Two-factor code was sent successfully"
  );
  // Values for extraction
  // t("common:success.sentTfaCode", "Two-factor code was sent successfully")

  const [mutation, { data, loading }] =
    useMutation<ResendTfaCodeMutation>(RESEND_TFA_CODE);

  const resendTfaCode = (
    input: ResendTfaCodeInput,
    onSuccess?: (account: AccountFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.resendTfaCode?.errors ?? [];
        const account = data?.resendTfaCode?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    resendTfaCode,
    isLoading: loading,
    account: data?.resendTfaCode?.account,
    errors: data?.resendTfaCode?.errors ?? [],
  };
};
