import React from "react";

import { TTailwindString, tw } from "utils/tw";

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type"
  > {
  id: string;
  name: string;
  label: string;
  value?: string;
  hint?: string;
  helpText?: string;
  errorMessage?: string;
  hideLabel?: boolean;
  isFullWidth?: boolean;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      hint,
      helpText,
      errorMessage,
      hideLabel = false,
      isFullWidth = false,
      className,
      ...props
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText ? `${id}-help` : "";
    const errorId = hasError ? `${id}-error` : "";
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}`.trim() : undefined;

    const selectContainerStyles = tw(className, "shrink-0", {
      "w-full": isFullWidth,
    });

    const labelContainerStyles = tw("flex", "justify-between", {
      "sr-only": hideLabel,
    });

    const labelStyles = tw("block", "text-sm", "text-gray-700", "mb-1");

    const hintStyles = tw("text-sm", "text-gray-500");

    const inputBase = tw(
      "appearance-none",
      "relative",
      "py-2",
      "px-3",
      "bg-white",
      "block",
      "w-full",
      "shadow-sm",
      "sm:text-sm",
      "rounded-md",
      "border",
      "focus-within:ring-1",
      "focus-within:ring-gray-900",
      "focus-within:border-gray-900"
    );
    const inputError = tw("border-2", "border-error", "bg-error-light");
    const inputDisabled = tw("bg-gray-100", "text-gray-700");
    const inputStyles = tw(inputBase, {
      "border-gray-300": !hasError,
      [inputError as string]: hasError,
      [inputDisabled as string]: props.disabled,
    });

    const textBase = tw("mt-2", "text-sm");
    const helpTextStyles = tw(textBase, "text-gray-700");
    const errorMessageStyles = tw(textBase, "text-error");

    return (
      <div className={selectContainerStyles}>
        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {hint && <span className={hintStyles}>{hint}</span>}
        </div>

        <label className={inputStyles}>
          <div className={tw("flex", "items-center", "space-x-2")}>
            <span
              className={tw(
                "block",
                "h-4",
                "w-4",
                "border",
                "border-deepBlue-100",
                "rounded"
              )}
              style={{ backgroundColor: props.value }}
            />

            <span className={tw("h-5", "text-sm", "text-deepBlue-900")}>
              {props.value}
            </span>
          </div>

          <input
            {...props}
            id={id}
            ref={ref}
            type="color"
            className={tw(
              "pointer-events-none",
              "opacity-0",
              "absolute",
              "top-0",
              "left-0",
              "h-full",
              "w-full"
            )}
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
          />
        </label>

        {helpTextId && (
          <p className={helpTextStyles} id={helpTextId}>
            {helpText}
          </p>
        )}

        {errorId && (
          <p className={errorMessageStyles} id={errorId}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
