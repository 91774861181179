import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { Button, Spinner } from "components/common/basic";
import { TierFragment } from "graphql/fragments";
import { useTiersQuery } from "graphql/queries";
import {
  PriceCurrencyEnum,
  PriceIntervalEnum,
  TierTypeEnum,
} from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import FeatureTable from "./FeatureTable";
import Tier from "./Tier";

interface Props {
  currency: PriceCurrencyEnum;
  interval: PriceIntervalEnum;
  currentTier?: TierFragment;
  onSelect?: (tier: TierFragment) => void;
  nonProfit?: boolean;
}

export default ({
  currency,
  interval,
  currentTier,
  onSelect,
}: Props): JSX.Element => {
  const [isShowingFeatures, setIsShowingFeatures] = useState(false);

  const { tiers, isLoading } = useTiersQuery({
    filter: { currency },
  });
  const basicMonthly = tiers?.find((tier) => tier.type === TierTypeEnum.Basic);
  const tiersForInterval = tiers.filter((tier) => tier.interval === interval);
  const sortedTiers = tiersForInterval.sort((a, b) =>
    a.amount > b.amount ? 1 : -1
  );
  const shownTiers = [
    ...(interval === PriceIntervalEnum.Year && basicMonthly
      ? [{ ...basicMonthly, interval }]
      : []),
    ...sortedTiers,
  ];

  const { t } = useTranslation("login");

  const getFeatures = (tier: TierTypeEnum) => {
    const prefix = tier.toLowerCase();

    switch (tier) {
      case TierTypeEnum.Enterprise:
        return [`${prefix}-1`, `${prefix}-2`];

      case TierTypeEnum.Plus:
        return [
          `${prefix}-1`,
          `${prefix}-2`,
          `${prefix}-3`,
          `${prefix}-4`,
          `${prefix}-5`,
        ];

      default:
        return [`${prefix}-1`, `${prefix}-2`, `${prefix}-3`, `${prefix}-4`];
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <ul className={tw("flex", "flex-col", "lg:flex-row", "gap-4")}>
        {shownTiers.map((tier) => {
          return (
            <Tier
              key={tier.id}
              tier={tier}
              currentTier={currentTier}
              onSelect={onSelect}
              planPrefix={`planSelection.plan_${tier.type.toLowerCase()}`}
              description={t(
                `planSelection.plan_${tier.type.toLowerCase()}.description`,
                `Description of ${tier.type}`
              )}
              purpose={t(
                `planSelection.plan_${tier.type.toLowerCase()}.purpose`,
                "Elevate your impact!"
              )}
              billingCycle={
                tier.amount === 0
                  ? t(
                      "planSelection.plan.billingCycle.free",
                      "Upgrade whenever you want"
                    )
                  : interval === PriceIntervalEnum.Year
                    ? t(
                        "planSelection.plan.billingCycle.annual",
                        "Per month - billed annually"
                      )
                    : t("planSelection.plan.billingCycle.monthly", "Per month")
              }
              features={getFeatures(tier.type)}
              isPopular={!currentTier && tier.type === TierTypeEnum.Premium}
            />
          );
        })}

        <Tier
          planPrefix={`planSelection.plan_enterprise`}
          description={t(
            "planSelection.plan_enterprise.description",
            "Contact us to get a custom offer that suits your organisations requirements, including API integrations to ensure that our platforms seamlessly ensure peace of mind."
          )}
          purpose={t(
            "planSelection.plan_enterprise.purpose",
            "Everything in Premium"
          )}
          tier={{
            type: TierTypeEnum.Enterprise,
            id: "enterprise",
            amount: -1,
            currency,
            interval,
            features: [],
            products: {} as TierFragment["products"],
          }}
          features={getFeatures(TierTypeEnum.Enterprise)}
        />
      </ul>

      <div className={tw("hidden", "md:flex", "justify-center")}>
        <Button
          id="tier_selection-compare_features"
          TrailingIcon={isShowingFeatures ? HiChevronUp : HiChevronDown}
          variant="secondary"
          size="md"
          onClick={() => setIsShowingFeatures(!isShowingFeatures)}
        >
          {t(
            "planSelection.features.button.showFeatures",
            "Compare all features"
          )}
        </Button>
      </div>

      {isShowingFeatures && (
        <FeatureTable
          tiers={shownTiers}
          currentTier={currentTier}
          onSelect={onSelect}
        />
      )}
    </>
  );
};
