import { gql } from "@apollo/client";

import { WorkExperience } from "graphql/types";

export const WORK_EXPERIENCE_FRAGMENT = gql`
  fragment WorkExperienceFragment on WorkExperience {
    company
    country
    current
    hrEmail
    managerEmail
    finishMonth
    position
    startMonth
  }
`;

export interface WorkExperienceFragment
  extends Pick<
    WorkExperience,
    | "company"
    | "country"
    | "current"
    | "hrEmail"
    | "managerEmail"
    | "finishMonth"
    | "position"
    | "startMonth"
  > {}
