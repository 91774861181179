import { gql } from "@apollo/client";

import { Check, CheckListStatusEnum, Event } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import { EDUCATION_FRAGMENT, EducationFragment } from "./education";
import { IDENTITY_FRAGMENT, IdentityFragment } from "./identity";
import {
  OPEN_SOURCE_SEARCH_FRAGMENT,
  OpenSourceSearchFragment,
} from "./openSourceSearch";
import {
  RELATED_COUNTRIES_FRAGMENT,
  RelatedCountriesFragment,
} from "./relatedCountries";
import {
  SPECIFIC_SOURCE_SEARCH_FRAGMENT,
  SpecificSourceSearchFragment,
} from "./specificSourceSearch";
import {
  WORK_EXPERIENCE_FRAGMENT,
  WorkExperienceFragment,
} from "./workExperience";

export const CHECK_FRAGMENT = gql`
  fragment CheckFragment on Check {
    id
    type
    status
    criminalRecordAttributes {
      files {
        ...AttachmentFragment
      }
    }
    educationAttributes {
      ...EducationFragment
    }
    workExperienceAttributes {
      workExperiences {
        ...WorkExperienceFragment
      }
      files {
        ...AttachmentFragment
      }
    }
    identityAttributes {
      identity {
        ...IdentityFragment
      }
    }
    informationAttributes {
      documents {
        ...AttachmentFragment
      }
    }
    eLearningAttributes {
      documents {
        ...AttachmentFragment
      }
    }
    relatedCountriesAttributes {
      ...RelatedCountriesFragment
    }
    openSourceSearchAttributes {
      ...OpenSourceSearchFragment
    }
    specificSourceSearchAttributes {
      ...SpecificSourceSearchFragment
    }
    checkList {
      id
      status
    }
    events {
      occurredAt
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${IDENTITY_FRAGMENT}
  ${EDUCATION_FRAGMENT}
  ${WORK_EXPERIENCE_FRAGMENT}
  ${RELATED_COUNTRIES_FRAGMENT}
  ${OPEN_SOURCE_SEARCH_FRAGMENT}
  ${SPECIFIC_SOURCE_SEARCH_FRAGMENT}
`;

export interface CheckFragment extends Pick<Check, "id" | "status" | "type"> {
  criminalRecordAttributes?: {
    files?: AttachmentFragment[];
  };
  educationAttributes?: EducationFragment;
  identityAttributes?: { identity?: IdentityFragment };
  workExperienceAttributes?: {
    workExperiences?: WorkExperienceFragment[];
    files?: AttachmentFragment[];
  };
  informationAttributes?: {
    documents?: AttachmentFragment[];
  };
  eLearningAttributes?: {
    documents?: AttachmentFragment[];
  };
  relatedCountriesAttributes?: RelatedCountriesFragment;
  openSourceSearchAttributes?: OpenSourceSearchFragment;
  specificSourceSearchAttributes?: SpecificSourceSearchFragment;
  checkList: {
    id: string;
    status: CheckListStatusEnum;
  };
  events: Pick<Event, "occurredAt">[];
}
