import { useContext, useState } from "react";

import { Button, Icon, IconType, ToggleButton } from "components/common/basic";
import { useRemoveTfaPhoneNumberMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  tfaPhone?: string;
}

export default ({ tfaPhone }: Props): JSX.Element => {
  const [isToggledOn, setIsToggledOn] = useState(Boolean(tfaPhone));

  const { removeTfaPhoneNumber } = useRemoveTfaPhoneNumberMutation();

  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  return (
    <div className={tw("space-y-6")}>
      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-bold")}>
          {t(
            "accountSettings.twoFactorSection.heading",
            "Two factor authentication"
          )}
        </h2>

        <p className={tw("text-sm", "text-deepBlue-500")}>
          {t(
            "accountSettings.twoFactorSection.description",
            "Add another layer of security to your account. You'll need to verify your phone number in addition to your password every time you sign in."
          )}
        </p>
      </div>

      <div
        className={tw(
          "py-6",
          "space-y-4",
          "rounded-lg",
          "border",
          "border-deepBlue-50"
        )}
      >
        <TwoFactorOption
          icon="HiOutlineChatAlt"
          label={t(
            "accountSettings.twoFactorSection.smsAuth.label",
            "SMS/Text message"
          )}
          description={t(
            "accountSettings.twoFactorSection.smsAuth.description",
            "Get one-time codes sent to your phone via SMS to complete authentication requests"
          )}
          isToggledOn={isToggledOn}
          toggle={() => {
            setIsToggledOn(!isToggledOn);

            if (tfaPhone)
              removeTfaPhoneNumber(
                {},
                () => setIsToggledOn(false),
                () => setIsToggledOn(true) // Revert to toggled on if phone number not removed
              );
          }}
          value={tfaPhone}
          errorMessage={
            isToggledOn && !tfaPhone
              ? t(
                  "accountSettings.twoFactorSection.smsAuth.missingPhone",
                  "A phone number is required"
                )
              : undefined
          }
          action={
            isToggledOn || tfaPhone ? (
              <Button
                id="accountSettings-add_2FA_phone"
                variant="tertiary"
                size="sm"
                onClick={() =>
                  openPopup({
                    id: "TwoFactorSetup",
                    props: { existingPhone: tfaPhone },
                  })
                }
              >
                {tfaPhone
                  ? t(
                      "accountSettings.twoFactorSection.smsAuth.button_edit.label",
                      "Edit phone number"
                    )
                  : t(
                      "accountSettings.twoFactorSection.smsAuth.button_add.label",
                      "Add phone number"
                    )}
              </Button>
            ) : undefined
          }
        />

        {/* <Divider />

        <TwoFactorOption
          icon="HiOutlineStar"
          label="LABEL"
          description="DESCRIPTION"
          isToggledOn={isToggledOn}
          toggle={() => setIsToggledOn(!isToggledOn)}
        /> */}
      </div>
    </div>
  );
};

interface TwoFactorOptionProps {
  icon: IconType;
  label: string;
  description: string;
  isToggledOn: boolean;
  toggle: () => void;
  value?: string;
  errorMessage?: string;
  action?: JSX.Element;
}
const TwoFactorOption = ({
  icon,
  label,
  description,
  isToggledOn,
  toggle,
  value,
  errorMessage,
  action,
}: TwoFactorOptionProps) => (
  <div className={tw("px-6", "flex", "items-start")}>
    <Icon icon={icon} size={24} color="text-deepBlue-900" margin="mr-2" />

    <div className={tw("flex-1", "space-y-4", "mr-12")}>
      <div className={tw("space-y-1")}>
        <h3 className={tw("font-bold", "text-deepBlue-900")}>{label}</h3>

        <p className={tw("text-sm", "text-deepBlue-500")}>{description}</p>

        {value && (
          <p className={tw("font-bold", "text-sm", "text-deepBlue-900")}>
            {value}
          </p>
        )}

        {errorMessage && (
          <p className={tw("text-sm", "text-error")}>{errorMessage}</p>
        )}
      </div>

      {action}
    </div>

    <ToggleButton isToggledOn={isToggledOn} onClick={toggle} label={label} />
  </div>
);
