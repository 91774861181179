import { lazy } from "react";

const ComponentLibrary = lazy(() => import("./ComponentLibrary"));
const CriiptoCallback = lazy(() => import("./CriiptoCallback"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const GateKeeper = lazy(() => import("./GateKeeper"));
const PayoutPartnerInfo = lazy(() => import("./PayoutPartnerInfo"));
const Privacy = lazy(() => import("./Privacy"));
const Terms = lazy(() => import("./Terms"));

export {
  ComponentLibrary,
  CriiptoCallback,
  ErrorPage,
  GateKeeper,
  PayoutPartnerInfo,
  Privacy,
  Terms,
};
