/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { AddTfaPhoneNumberInput } from "graphql/types";

const ADD_TFA_PHONE_NUMBER = gql`
  mutation ($input: AddTfaPhoneNumberInput!) {
    addTfaPhoneNumber(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface AddTfaPhoneNumberMutation {
  addTfaPhoneNumber: Payload | null;
}

export const useAddTfaPhoneNumberMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    AddTfaPhoneNumberMutation,
    { input: AddTfaPhoneNumberInput }
  >(ADD_TFA_PHONE_NUMBER);

  const addTfaPhoneNumber = (
    input: AddTfaPhoneNumberInput,
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.addTfaPhoneNumber?.errors ?? [];
        const account = data?.addTfaPhoneNumber?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    addTfaPhoneNumber,
    isLoading: loading,
    account: data?.addTfaPhoneNumber?.account,
    errors: data?.addTfaPhoneNumber?.errors ?? [],
  };
};
