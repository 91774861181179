import { useEffect, useRef, useState } from "react";

import { useDebounce, useMenu } from "hooks";

import { SearchWrapper, SingleSelectResults } from "../shared";
import { BESingleProps } from "../shared/types";

export default <T,>({
  id,
  itemKey,
  items,
  page,
  pages,
  setPage,
  onSearch,
  renderListItemLabel,
  onSelect,
  buttonLabel,
  label,
  placeholder,
  hint,
  listHeader,
  footer,
  emptyState,
  isLoading,
  errorMessage,
  fixedHeight = "h-96",
  reloadOnOpen = false,
  showPagination = true,
}: Omit<BESingleProps<T>, "isMultiSelect">): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 750);

  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useMenu(menuRef);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    onSearch(searchInput);
  }, [debouncedValue]);

  useEffect(() => {
    if (!reloadOnOpen || !isOpen) return;

    onSearch(searchInput);
  }, [isOpen]);

  return (
    <SearchWrapper
      menuRef={menuRef}
      id={id}
      defaultSearchInput={searchInput}
      onSearch={setSearchInput}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      buttonLabel={
        typeof buttonLabel === "function" ? buttonLabel(isOpen) : buttonLabel
      }
      label={label}
      placeholder={placeholder}
      hint={hint}
      isLoading={isLoading}
      errorMessage={errorMessage}
      fixedHeight={fixedHeight}
    >
      <SingleSelectResults<T>
        listHeader={listHeader}
        itemKey={itemKey}
        items={items}
        renderListItemLabel={renderListItemLabel}
        onSelect={onSelect}
        onClose={() => setIsOpen(false)}
        page={page}
        pages={pages}
        setPage={setPage}
        emptyState={emptyState}
        footer={footer}
        showPagination={showPagination}
      />
    </SearchWrapper>
  );
};
