import React, { useContext } from "react";
import DatePicker from "react-datepicker";

import moment from "moment";

import { AuthContext } from "providers/Authentication";
import { TArg, tw } from "utils/tw";

import { Icon } from "../basic";

export interface Props {
  id: string;
  label: string;
  placeholder: string;
  onChange: (monthDate: string) => void;
  selected?: string;
  hint?: string;
  helpText?: string;
  errorMessage?: string;
  hideLabel?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  className?: TArg;
}

export default React.forwardRef(
  (
    {
      id,
      label,
      placeholder,
      onChange,
      selected,
      hint,
      helpText,
      errorMessage,
      hideLabel = false,
      fullWidth = false,
      isDisabled = false,
      className,
    }: Props,
    ref: React.ForwardedRef<DatePicker>
  ): JSX.Element => {
    const { session } = useContext(AuthContext);

    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText ? `${id}-help` : "";
    const errorId = hasError ? `${id}-error` : "";
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}`.trim() : undefined;

    const containerStyles = tw(className, { "w-full": fullWidth });

    const labelContainerStyles = tw("flex", "justify-between");

    const labelStyles = tw("block", "text-sm", "text-gray-700", {
      "sr-only": hideLabel,
    });

    const hintStyles = tw("text-sm", "text-gray-500");

    const inputContainerStyles = tw(
      "mt-1",
      "relative",
      "rounded-md",
      "shadow-sm"
    );

    const inputBase = tw(
      "block",
      "w-full",
      "pr-10",
      "shadow-sm",
      "sm:text-sm",
      "rounded-md",
      "focus:ring-gray-900",
      "focus:border-gray-900"
    );
    const inputError = tw("border-2", "border-error", "bg-error-light");
    const inputDisabled = tw("bg-gray-100", "text-gray-700");
    const inputStyles = tw(inputBase, {
      "border-gray-300": !hasError,
      [inputError as string]: hasError,
      [inputDisabled as string]: isDisabled,
    });

    const trailingIconWrapperStyles = tw(
      "px-3",
      "absolute",
      "inset-y-0",
      "flex",
      "items-center",
      "pointer-events-none",
      "right-0"
    );

    const textBase = tw("mt-2", "text-sm");
    const helpTextStyles = tw(textBase, "text-gray-700");
    const errorMessageStyles = tw(textBase, "text-error");

    return (
      <div className={containerStyles}>
        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {hint && <span className={hintStyles}>{hint}</span>}
        </div>

        <div className={inputContainerStyles}>
          <DatePicker
            className={inputStyles}
            wrapperClassName={tw("w-full")}
            ref={ref}
            locale={session?.user.language.toLocaleLowerCase()}
            selected={selected ? moment(selected, "YYYY-MM").toDate() : null}
            onChange={(date) => onChange(moment(date).format("YYYY-MM"))}
            placeholderText={placeholder}
            renderMonthContent={(_monthIndex, shortMonth, longMonth, day) => {
              const fullYear = new Date(day).getFullYear();
              const tooltipText = `${longMonth} ${fullYear}`;

              return <span title={tooltipText}>{shortMonth}</span>;
            }}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            disabled={isDisabled}
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
          />

          <div className={trailingIconWrapperStyles}>
            <Icon
              icon="HiOutlineCalendar"
              size={20}
              color={hasError ? "text-error" : "text-gray-400"}
            />
          </div>
        </div>

        {helpTextId && (
          <p className={helpTextStyles} id={helpTextId}>
            {helpText}
          </p>
        )}

        {errorId && (
          <p className={errorMessageStyles} id={errorId}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
