import { useState } from "react";

import { Divider, Icon, Link } from "components/common/basic";
import { SessionFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { logo } from "utils/brand";
import { tw } from "utils/tw";

import { MenuList } from "./common";
import useSideMenuItems from "./common/useSideMenuItems";

interface Props {
  session: SessionFragment;
}

export default ({ session }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation("common");

  const sideMenuItems = useSideMenuItems({ session });

  const sideMenuStyles = tw(
    "sticky",
    "top-0",
    "h-screen",
    "overflow-auto",
    "z-50",
    "bg-white",
    "shadow-lg",
    "shrink-0",
    "flex",
    "flex-col",
    "items-center",
    { "w-72": isExpanded }
  );

  const logoStyles = tw("h-6", "w-auto");

  return (
    <div className={sideMenuStyles}>
      <div
        className={tw("my-4", "p-4", "flex", "justify-between", {
          "w-full": isExpanded,
        })}
      >
        {isExpanded && (
          <Link
            id="sideMenu-go_to_homepage"
            to={t("links.homepage", "https://manymore.com/en")}
            isExternal
            unstyled
          >
            <img className={logoStyles} src={logo} alt="logo" />
          </Link>
        )}

        <button onClick={() => setIsExpanded(!isExpanded)}>
          <Icon
            color="text-gray-900"
            icon={isExpanded ? "MmMenuClose" : "MmMenuOpen"}
            size={24}
          />
        </button>
      </div>

      <div className={tw("w-full", "px-4")}>
        <Divider />
      </div>

      <MenuList iconsOnly={!isExpanded} menuItems={sideMenuItems} />
    </div>
  );
};
