/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { CreateVeriffSessionInput, VeriffSession } from "graphql/types";

const CREATE_VERIFF_SESSION = gql`
  mutation ($input: CreateVeriffSessionInput!) {
    createVeriffSession(input: $input) {
      veriffSession {
        sessionUrl
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  veriffSession?: Pick<VeriffSession, "sessionUrl">;
  errors: ErrorFragment[];
}

export interface CreateVeriffSessionMutation {
  createVeriffSession: Payload | null;
}

export const useCreateVeriffSessionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateVeriffSessionMutation,
    { input: CreateVeriffSessionInput }
  >(CREATE_VERIFF_SESSION);

  const createVeriffSession = (
    input: CreateVeriffSessionInput,
    onSuccess?: (sessionUrl: string) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createVeriffSession?.errors ?? [];
        const sessionUrl = data?.createVeriffSession?.veriffSession?.sessionUrl;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => {
            if (sessionUrl) onSuccess?.(sessionUrl);
          },
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createVeriffSession,
    veriffSession: data?.createVeriffSession?.veriffSession,
    isLoading: loading,
    errors: data?.createVeriffSession?.errors ?? [],
  };
};
