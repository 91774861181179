import { IconBaseProps } from "react-icons";

export default ({
  size,
  strokeWidth = "1.5",
  ...props
}: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.8,18.9c-1.1,0.8-2.4,1.4-3.8,1.7c-5.2-1.3-9-6-9-11.6c0-1,0.1-2.1,0.4-3C3.6,6,3.8,6,4,6
	c3.1,0,5.9-1.2,8-3.1C14.1,4.8,16.9,6,20,6c0.2,0,0.4,0,0.6,0c0.2,1,0.4,2,0.4,3c0,1-0.1,2-0.4,2.9"
    />

    <path
      fill="currentColor"
      d="M11.8,15.3V9l-0.9,0.3c-0.1,0-0.2,0-0.3,0C10.3,9.3,10,9,10,8.7c0-0.3,0.2-0.6,0.5-0.6l1.3-0.4
	c0.3-0.1,0.5-0.1,0.7-0.1h0c0.4,0,0.7,0.3,0.7,0.7v7.1c0,0.4-0.3,0.7-0.7,0.7C12.1,16.1,11.8,15.7,11.8,15.3z"
    />

    <g>
      <line
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="16.2"
        y1="16"
        x2="21.1"
        y2="16"
      />
      <line
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="18.6"
        y1="18.5"
        x2="18.6"
        y2="13.5"
      />
    </g>
  </svg>
);
