import { TFunction } from "i18next";
import * as Yup from "yup";

export const emailValidationSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(t("common:error.email.required", "An email address is required"))
    .email(t("common:error.email.format", "Incorrect email format"));

export const passwordValidationSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(t("common:error.password.required", "A password is required"))
    .min(
      6,
      t(
        "common:error.password.passwordTooShort",
        "Minimum 6 characters, please!"
      )
    )
    .max(
      64,
      t(
        "common:error.password.passwordTooLong",
        "No more than 64 characters, please!"
      )
    );

export const confirmationCodeSchema = (t: TFunction): Yup.StringSchema =>
  Yup.string()
    .required(
      t(
        "common:error.confirmationCode.required",
        "Confirmation code is required"
      )
    )
    .matches(
      /[0-9]+/,
      t(
        "common:error.confirmationCode.format",
        "Confirmation should only include numbers"
      )
    )
    .length(
      5,
      t(
        "common:error.confirmationCode.length",
        "Confirmation code must consist of 5 digits"
      )
    );
