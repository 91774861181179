import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useDefaultRoutePath, useRoutePaths } from "utils";

export default (): null => {
  const { t } = useTranslation("common");
  const getRoutePath = useRoutePaths();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const defaultRoutePath = useDefaultRoutePath();

  const isTest = [
    "qa.my.manymore.com",
    "rc.my.manymore.com",
    "localhost",
  ].includes(window.location.hostname);

  useEffect(() => {
    if (isLoggedIn) return navigate(defaultRoutePath);

    if (isTest)
      return navigate(getRoutePath({ module: "login", routeName: "LOGIN" }));

    window.location.href = t("links.homepage", "https://manymore.com/en");
  }, []);

  return null;
};
