import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Input } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import { AccountFragment } from "graphql/fragments";
import { useUpdateAccountMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { AvatarUpload } from "../composite";

interface FormValues {
  name: string;
}

interface Props {
  hasPrevious: boolean;
  onSuccess?: (account: AccountFragment) => void;
}

export default ({ onSuccess, ...props }: Props): JSX.Element | null => {
  const { session } = useContext(AuthContext);
  const { closeAllPopups } = useContext(PopupContext);
  if (!session) {
    closeAllPopups();

    return null;
  }
  const { account } = session;

  const { updateAccount } = useUpdateAccountMutation();

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t("popup.teamInfo.name.error.required", "A name is required")
    ),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: { name: account.name ?? "" },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const name = watch("name");

  const onSubmit = ({ name }: FormValues) =>
    updateAccount({ attributes: { name } }, (teamAccount) => {
      onSuccess?.(teamAccount);

      closeAllPopups();
    });

  const formId = "teamInfoForm";

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t("popup.teamInfo.title", "{{ teamName }} info", {
        teamName: account.name,
      })}
      footerActions={[
        {
          id: "teamInfo_popup-submit",
          variant: "primary",
          type: "submit",
          form: formId,
          label: t("popup.teamInfo.submit", "Save"),
          disabled: !name,
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-6")}>
        <div className={tw("flex", "flex-col", "space-y-4")}>
          <div className={tw("space-y-2")}>
            <h2 className={tw("font-bold")}>
              {t("popup.teamInfo.symbol", "Add a symbol")}
            </h2>

            <AvatarUpload account={account} />
          </div>
        </div>

        <form
          className={tw("w-full", "space-y-4")}
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            id="name"
            {...register("name")}
            label={t("popup.teamInfo.form.name.label", "Team space name")}
            placeholder={t("popup.teamInfo.form.name.placeholder", "Name")}
            errorMessage={errors.name?.message}
          />
        </form>
      </div>
    </PopupWrapper>
  );
};
