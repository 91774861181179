import { useContext } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { SessionFragment } from "graphql/fragments";
import { useDeleteAccountMutation } from "graphql/mutations";
import { AccountTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { Divider } from "../basic";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props): JSX.Element | null => {
  const { deleteAccount } = useDeleteAccountMutation();

  const { session } = useContext(AuthContext);
  const { closeAllPopups } = useContext(PopupContext);
  const { t } = useTranslation("account");

  if (!session) return null;

  const isTeam = session.account.accountType === AccountTypeEnum.Team;
  const ownedTeams = isTeam
    ? []
    : session.accounts.reduce(
        (acc, account) =>
          account.accountType === AccountTypeEnum.Team &&
          account.primaryUser?.id === session.user.id
            ? [...acc, account]
            : acc,
        [] as SessionFragment["accounts"]
      );

  return (
    <PopupWrapper
      {...props}
      leadingIcon="HiExclamation"
      leadingIconColor="text-error"
      title={
        isTeam
          ? t("popup.deleteAccount_team.title", "Delete my team")
          : t("popup.deleteAccount_personal.title", "Delete my account")
      }
      variant="popup"
      footerActions={[
        {
          id: "delete_account-close_popup",
          variant: "tertiary",
          label: t("popup.deleteAccount.action.close", "Cancel"),
          onClick: closeAllPopups,
        },
        ...(ownedTeams.length > 0
          ? []
          : [
              {
                id: "delete_account-delete_account" as const,
                variant: "danger" as const,
                label: isTeam
                  ? t(
                      "popup.deleteAccount_team.action.delete",
                      "Delete my team account"
                    )
                  : t(
                      "popup.deleteAccount_personal.action.delete",
                      "Delete my account"
                    ),
                onClick: () => deleteAccount({}, closeAllPopups),
              },
            ]),
      ]}
    >
      <div className={tw("space-y-6")}>
        {ownedTeams.length > 0 && (
          <div className={tw("p-4", "bg-silver", "rounded-lg", "space-y-4")}>
            <h3 className={tw("text-deepBlue-900", "text-lg", "font-bold")}>
              {t(
                "popup.deleteAccount_personal.ownedTeams.heading",
                "You are a team owner of:"
              )}
            </h3>

            <Divider borderColor="border-deepBlue-50" />

            <ul className={tw("list-disc", "list-inside", "space-y-2")}>
              {ownedTeams.map((team) => (
                <li
                  key={team.id}
                  className={tw(
                    "text-deepBlue-900",
                    "text-sm",
                    "list-disc",
                    "list-inside"
                  )}
                >
                  {team.name}
                </li>
              ))}
            </ul>
          </div>
        )}

        <p
          className={tw("text-deepBlue-700", "text-lg", "whitespace-pre-wrap")}
        >
          {isTeam
            ? t(
                "popup.deleteAccount_team.body",
                `Deleting your team is a big step. We will schedule your team for deletion, including all your files, verification, checks, and any other information associated with your team.

All your team members will lose access, and none of the data collected so far will be retained.

The deletion itself will take place within 14 days. You can cancel your account deletion until then.`
              )
            : ownedTeams.length > 0
              ? t(
                  "popup.deleteAccount_personal_teamOwner.body",
                  `Before you can delete your private account, could you please transfer ownership to another team member or delete the team accounts associated with your account first.

In order to delete your private account, we need to ensure that all your teams have a new owner, or that they are deleted as well.`
                )
              : t(
                  "popup.deleteAccount_personal.body",
                  `Deleting your account is a big step. We will schedule your account for deletion, including all your files, verification, checks, and any other information associated with your account.

If you are a member of a team, then your membership will also be deleted. You will not longer be able to loq in to Manymore.com with
your email.

The deletion itself will take place within 14 days. You can cancel your account deletion until then.`
                )}
        </p>
      </div>
    </PopupWrapper>
  );
};
