import { gql } from "@apollo/client";

import { RelatedCountriesCheckAttributes } from "graphql/types";

export const RELATED_COUNTRIES_FRAGMENT = gql`
  fragment RelatedCountriesFragment on RelatedCountriesCheck {
    affiliationCountries
    assetsCountries
    familyCountries
    incomeCountries
    legalCountries
    presenceCountries
  }
`;

export interface RelatedCountriesFragment
  extends Pick<
    RelatedCountriesCheckAttributes,
    | "affiliationCountries"
    | "assetsCountries"
    | "familyCountries"
    | "incomeCountries"
    | "legalCountries"
    | "presenceCountries"
  > {}
