import { useContext } from "react";

import { Icon } from "components/common/basic";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import {
  getCountries,
  getCountryNameFromCode,
  getNonSanctionedCountries,
} from "utils";

import SingleSearch from "./SingleSearch";

type CountryField = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  setCountry: (countryCode: string) => void;
  country?: string;
  label?: string;
  errorMessage?: string;
  useNonSanctionedList?: boolean;
};

export default ({
  id,
  setCountry,
  country,
  label,
  errorMessage,
  useNonSanctionedList = false,
}: Props): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);
  const { t } = useTranslation("checkmore");

  const countryCodes = useNonSanctionedList
    ? getNonSanctionedCountries()
    : getCountries();

  const countryFields = countryCodes.map((countryCode) => ({
    label: getCountryNameFromCode(countryCode, currentLocale),
    value: countryCode,
  }));

  const handleSearch = (input: string): Promise<CountryField[]> => {
    return new Promise((resolve) => {
      const fields = input
        ? countryFields.filter((country) =>
            country.label.toLowerCase().includes(input.toLowerCase())
          )
        : countryFields;

      resolve(fields);
    });
  };

  return (
    <SingleSearch
      id={id}
      label={label ?? t("search_countriesSingle.label", "Select the country")}
      renderListItemLabel={(item) => <>{item.label}</>}
      buttonLabel={(isOpen) => (
        <>
          {country
            ? getCountryNameFromCode(country, currentLocale)
            : t("search_countriesSingle.button.label.empty", "Select")}
          <Icon
            icon={isOpen ? "HiOutlineChevronUp" : "HiOutlineChevronDown"}
            size={20}
          />
        </>
      )}
      itemKey="value"
      debounceTime={0}
      perPage={10}
      onSearch={handleSearch}
      onSelect={({ value }) => setCountry(value)}
      errorMessage={errorMessage}
    />
  );
};
