import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Icon } from "components/common/basic";
import { PopupWrapper } from "components/common/popups";
import {
  PublicWbHandlerFragment,
  WbHandlerFragment,
  WbReportFragment,
} from "graphql/fragments";
import { useUpdateWbReportMutation } from "graphql/mutations";
import { useWbHandlersQuery } from "graphql/queries";
import { AccountTypeEnum, WbReportTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { Banner } from "../composite";
import MultiSearch from "../composite/search/FEPaginated/MultiSearch";

interface FormValues {
  reportHandlers: (PublicWbHandlerFragment | WbHandlerFragment)[];
}

interface Props {
  wbReport: WbReportFragment;
  onSuccess?: (wbReport: WbReportFragment) => void;
  hasPrevious: boolean;
}

export default ({ wbReport, onSuccess, ...props }: Props): JSX.Element => {
  const { wbHandlers, isLoading } = useWbHandlersQuery();
  const { updateWbReport } = useUpdateWbReportMutation();

  const { session } = useContext(AuthContext);
  const { closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    reportHandlers: Yup.array()
      .required()
      .when("type", {
        is: () => wbReport.type === WbReportTypeEnum.Whistleblowing,
        then: (schema) =>
          schema.min(
            1,
            t(
              "popup.assignWbHandlers.form.error.reportHandlers.atLeastOne",
              "Select at least one report handler"
            )
          ),
      }),
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm<FormValues>({
    defaultValues: { reportHandlers: wbReport.wbHandlers },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const reportHandlers = watch("reportHandlers");

  const onSubmit = (values: FormValues) => {
    const attributes = {
      wbHandlerIds: values.reportHandlers.map(({ id }) => id),
    };

    updateWbReport(wbReport.id, attributes, (wbReport) => {
      onSuccess?.(wbReport);

      closeOnePopup();
    });
  };
  const formId = "assignWbHandlersForm";

  const personalAccountId = session?.accounts.find(
    (account) => account.accountType === AccountTypeEnum.Personal
  )?.id;
  const willLoseAccess =
    !isLoading &&
    reportHandlers.length > 0 &&
    !reportHandlers.some((formHandler) => {
      const handler = wbHandlers.find(
        (handler) => handler.id === formHandler.id
      );
      if (!handler) return false;

      return handler.account.id === personalAccountId;
    });

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={t(
        "popup.assignWbHandlers.title",
        "Are you sure you want to re-assign report handlers to this report?"
      )}
      subTitle={t(
        "popup.assignWbHandlers.subTitle",
        "Changing report handers could have significant impact on the outcome of the case. Please think twice before taking any action."
      )}
      leadingIcon="HiExclamation"
      leadingIconColor="text-error"
      footerActions={[
        {
          id: "assign_handlers-assign_handlers",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("popup.assignWbHandlers.submit", "Save"),
        },
      ]}
    >
      <form
        className={tw("w-full", "space-y-4")}
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <MultiSearch<PublicWbHandlerFragment | WbHandlerFragment>
          id="reportHandlers"
          label={t(
            "popup.assignWbHandlers.form.reportHandlers.label",
            "Report handlers"
          )}
          onSelect={(items) => {
            setValue("reportHandlers", items);
            trigger("reportHandlers");
          }}
          selectedItems={reportHandlers}
          renderListItemLabel={({ name, title }) => (
            <p>
              <span>{name}</span>
              {title && <span className={tw("text-blue-500")}> {title}</span>}
            </p>
          )}
          onSearch={(searchInput) =>
            new Promise((resolve) =>
              resolve(
                wbHandlers.filter(({ name }) =>
                  name.toLowerCase().includes(searchInput.toLowerCase())
                )
              )
            )
          }
          reloadOnOpen
          itemKey="id"
          buttonLabel={
            <>
              {((amount) => {
                switch (amount) {
                  case 0:
                    return t(
                      "popup.assignWbHandlers.form.reportHandlers_none.buttonLabel",
                      "Select a report handler"
                    );

                  case 1:
                    return (
                      <p>
                        <span>{reportHandlers[0].name}</span>

                        {reportHandlers[0].title && (
                          <span className={tw("text-blue-500")}>
                            {" "}
                            {reportHandlers[0].title}
                          </span>
                        )}
                      </p>
                    );

                  default:
                    return t(
                      "popup.assignWbHandlers.form.reportHandlers_many.buttonLabel",
                      "{{ amount }} selected",
                      { amount }
                    );
                }
              })(reportHandlers.length)}

              <Icon
                icon="HiChevronDown"
                isFilled
                size={20}
                color="text-gray-400"
              />
            </>
          }
          errorMessage={errors.reportHandlers?.message}
        />

        {willLoseAccess && (
          <Banner
            variant="danger"
            title={t(
              "popup.assignWbHandlers.banner_removeOwnAccess.title",
              "Warning!"
            )}
            body={t(
              "popup.assignWbHandlers.banner_removeOwnAccess.body",
              "You are about to remove yourself. This action will revoke your access to this report. Proceed with caution."
            )}
          />
        )}
      </form>
    </PopupWrapper>
  );
};
