import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Link } from "components/common/basic";
import {
  PhoneCountry,
  PhoneNumberInput,
  getCountries,
} from "components/common/composite";
import {
  useAddTfaPhoneNumberMutation,
  useResendTfaPhoneNumberConfirmationMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupVariant, PopupWrapper } from "../PopupWrapper";
import { ValidateSMSCodeForm } from "./ValidateSMSCodeForm";

interface Props {
  existingPhone?: string;
  onSuccess?: () => void;
  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default ({ existingPhone, onSuccess, ...props }: Props): JSX.Element => {
  const [hasSentCode, setHasSentCode] = useState(false);

  const { addTfaPhoneNumber, isLoading } = useAddTfaPhoneNumberMutation();
  const { resendTfaPhoneNumberConfirmation } =
    useResendTfaPhoneNumberConfirmationMutation();

  const { session } = useContext(AuthContext);
  const { closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    country: Yup.mixed<PhoneCountry>().required(),
    number: Yup.string()
      .matches(/\d+/, {
        excludeEmptyString: true,
        message: t(
          "popup.twoFactorSetup.sendSMSCode.phoneNumber.error.format",
          "Incorrect phone format (only numbers)"
        ),
      })
      .required(
        t(
          "popup.twoFactorSetup.sendSMSCode.phoneNumber.error.required",
          "A phone number is required"
        )
      ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<{
    country: PhoneCountry;
    number: string;
  }>({
    defaultValues: {
      country:
        getCountries().find(
          ({ countryCode }) => countryCode === session?.account.country
        ) ?? getCountries()[0],
      number: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const phone = watch();

  const getCodeBySMS = handleSubmit((values) => {
    const attributes = {
      countryCode: values.country.phonePrefix,
      number: values.number,
    };
    addTfaPhoneNumber({ attributes }, () => setHasSentCode(true));
  });

  const formStyles = tw(
    "px-4",
    "py-6",
    "shadow-md",
    "rounded-lg",
    "flex",
    "flex-col",
    "gap-6",
    "items-center"
  );

  return (
    <PopupWrapper
      {...props}
      title={
        hasSentCode
          ? t(
              "popup.twoFactorSetup.validateSMSCode.heading",
              "We sent a text to your phone"
            )
          : t(
              "popup.twoFactorSetup.sendSMSCode.heading",
              "Two factor authentication"
            )
      }
    >
      <div className={tw("flex", "flex-col", "gap-6")}>
        {hasSentCode ? (
          <>
            <h2>
              {t(
                "popup.twoFactorSetup.validateSMSCode.subTitle",
                "Enter the 6-digit code we just texted to your phone number {{ phone }}",
                { phone: `${phone.country.phonePrefix} ${phone.number}` }
              )}
            </h2>

            <p>
              <Trans
                ns="common"
                i18nKey="popup.twoFactorSetup.validateSMSCode.resendSMS"
                defaults="Didn't get a text? <0>Send again</0>."
                components={[
                  <button
                    id="two_factor_popup-send_sms_code"
                    className={tw(
                      "underline",
                      "text-sm",
                      "font-semibold",
                      "text-deepBlue-900"
                    )}
                    onClick={() => resendTfaPhoneNumberConfirmation({})}
                    disabled={isLoading}
                  />,
                ]}
              />
            </p>
          </>
        ) : existingPhone ? (
          <div className={tw("space-y-2")}>
            <p className={tw("font-bold", "text-lg", "text-deepBlue-900")}>
              {t(
                "popup.twoFactorSetup.sendSMSCode.existingPhone",
                "Existing phone number: {{ phone }}",
                { phone: existingPhone }
              )}
            </p>

            <p>
              {t(
                "popup.twoFactorSetup.sendSMSCode_hasExisting.instructions",
                "Add a new phone number and verify it by receiving a SMS code!"
              )}
            </p>
          </div>
        ) : (
          <>
            <p>
              {t(
                "popup.twoFactorSetup.sendSMSCode.instructions",
                "Add your phone number and verify it by receiving a SMS code!"
              )}
            </p>
          </>
        )}

        {hasSentCode ? (
          <ValidateSMSCodeForm
            phoneNumber={{
              countryCode: phone.country.phonePrefix,
              number: phone.number,
            }}
            onSuccess={() => {
              onSuccess?.();
              closeOnePopup();
            }}
            formStyles={formStyles}
          />
        ) : (
          <form onSubmit={getCodeBySMS} className={formStyles}>
            <PhoneNumberInput
              id="phone"
              label={t(
                "popup.twoFactorSetup.sendSMSCode.phoneNumber.label",
                "Phone number"
              )}
              selectedCountry={phone.country}
              setSelectedCountry={(value) => setValue("country", value)}
              registerNumber={register("number")}
              disabled={hasSentCode}
              errorMessage={errors.number?.message}
              isFullWidth
            />

            <div
              className={tw("w-full", "flex", "justify-between", "items-end")}
            >
              <div className={tw("space-y-1")}>
                <p className={tw("text-sm", "text-deepBlue-900")}>
                  {t(
                    "popup.twoFactorSetup.sendSMSCode.goToHelp.heading",
                    "Need help?"
                  )}
                </p>

                <Link
                  id="two_factor_popup-go_to_help"
                  isExternal
                  to={t(
                    "popup.twoFactorSetup.sendSMSCode.goToHelp.link",
                    "https://en.support.manymore.com"
                  )}
                  color="deepBlue"
                  className={tw("text-sm", "font-semibold")}
                >
                  {t(
                    "popup.twoFactorSetup.sendSMSCode.goToHelp.label",
                    "Read our FAQ"
                  )}
                </Link>
              </div>

              <Button
                id="two_factor_popup-send_sms_code"
                disabled={isLoading}
                type="submit"
              >
                {t(
                  "popup.twoFactorSetup.sendSMSCode.button.submit",
                  "Send SMS code"
                )}
              </Button>
            </div>
          </form>
        )}
      </div>
    </PopupWrapper>
  );
};
