/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  CHECK_LIST_FRAGMENT,
  CheckListFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { CHECK_LIST, CheckListQuery } from "graphql/queries";

const REFUSE_CHECK_LIST = gql`
  mutation ($id: ID!, $message: String!) {
    refuseCheckList(input: { id: $id, refuseReason: $message }) {
      checkList {
        ...CheckListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CHECK_LIST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  checkList: CheckListFragment;
  errors: ErrorFragment[];
}

interface RefuseCheckListMutation {
  refuseCheckList: Payload | null;
}

export const useRefuseCheckListMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    RefuseCheckListMutation,
    { id: string; message: string }
  >(REFUSE_CHECK_LIST);

  const refuseCheckList = (
    variables: { id: string; message: string },
    onSuccess?: () => void
  ) =>
    mutation({
      variables,
      update(cache, { data }) {
        cache.modify({
          fields: {
            node() {
              const updatedCheckList = data?.refuseCheckList?.checkList;
              if (!updatedCheckList) return;

              const checkListQuery = cache.readQuery<CheckListQuery>({
                query: CHECK_LIST,
              });
              if (!checkListQuery) return;

              const isCurrentCheckList =
                checkListQuery.node.id === updatedCheckList.id;
              if (!isCurrentCheckList) return;

              cache.writeQuery<CheckListQuery>({
                query: CHECK_LIST,
                data: { node: updatedCheckList },
              });
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data?.refuseCheckList?.checkList && onSuccess) onSuccess();
      })
      .catch((_error: ApolloError) => null);

  return {
    refuseCheckList,
    isLoading: loading,
    checkList: data?.refuseCheckList?.checkList,
  };
};
