import { AddressFragment } from "graphql/fragments";
import { PriceCurrencyEnum } from "graphql/types";
import { useTranslation } from "translations";

interface Formatter {
  getFormattedAddress: (address: AddressFragment) => string;
  getFormattedMoney: (
    amount: number,
    currency?: PriceCurrencyEnum | null
  ) => string;
}

export default (): Formatter => {
  const { t } = useTranslation("common");

  const getFormattedAddress = ({
    line1,
    postalCode,
    city,
    country,
  }: AddressFragment) => {
    const firstSection = `${line1}, `;

    const secondSection =
      postalCode && city ? `${postalCode} ${city}, ` : city ? `${city}, ` : "";

    const thirdSection = country
      ? t(`country.${country?.toLowerCase()}`, country)
      : "";

    return firstSection + secondSection + thirdSection;
  };

  const getFormattedMoney = (
    amountInCents: number,
    currency?: PriceCurrencyEnum | null
  ): string => {
    const [integer, fractional] = (amountInCents / 100).toFixed(2).split(".");

    const thousandSeparator = t("thousandSeparator", ",");
    const decimalSeparator = t("decimalSeparator", ".");

    const separatedInteger = integer.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandSeparator
    );

    const amount = separatedInteger + decimalSeparator + fractional;

    if (currency) return `${amount} ${currency}`;
    return amount;
  };

  return { getFormattedAddress, getFormattedMoney };
};
