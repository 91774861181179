import { useContext } from "react";
import {
  HiOutlineGlobeAlt,
  HiOutlineLogin,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import { LanguageEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { getLocalePrefix } from "utils";
import { tw } from "utils/tw";

import { MenuList } from ".";

interface Props {
  showLanguageSelect?: boolean;
}

export default ({ showLanguageSelect = false }: Props): JSX.Element | null => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout, session } = useContext(AuthContext);
  const { currentLocale } = useContext(IntlContext);

  if (!session) return null;

  const getLabelFromLanguage = (lang: LanguageEnum) => {
    const language = t(`layout.topMenu.language.${lang.toLowerCase()}`, lang);
    const label = t("layout.topMenu.languageSelect.option", "{{ language }}", {
      language,
    });

    return label;

    // Values for extraction
    // t("common:language.en", "English")
    // t("common:language.nb", "Norwegian")
  };

  const changeLanguage = (language: LanguageEnum) => {
    const currentLocalePrefix = getLocalePrefix(currentLocale);
    const newLocalePrefix = getLocalePrefix(language);

    const newPath = pathname.replace(currentLocalePrefix, newLocalePrefix);

    return navigate(newPath);
  };

  const isDeletingAccount = Boolean(session?.account.deletionScheduledOn);

  return (
    <MenuList
      menuItems={[
        ...(showLanguageSelect
          ? [
              {
                id: "top_menu_list-language_select" as const,
                variant: "default" as const,
                Icon: HiOutlineGlobeAlt,
                label: getLabelFromLanguage(currentLocale),
                items: Object.values(LanguageEnum).map((value) => ({
                  id: "top_menu_list-language_select" as const,
                  label: getLabelFromLanguage(value),
                  onClick: () => changeLanguage(value),
                })),
              },
            ]
          : []),
        ...(isDeletingAccount
          ? []
          : [
              {
                id: "top_menu_list-go_to_help" as const,
                Icon: HiQuestionMarkCircle,
                label: t("layout.topMenu.link.helpCenter", "Help center"),
                to: t("common:links.help", "https://en.support.manymore.com"),
                isExternal: true,
                iconClassName: tw("text-blue-900"),
              },
            ]),
        {
          id: "top_menu_list-log_out" as const,
          Icon: HiOutlineLogin,
          label: t("layout.topMenu.button.logOut", "Log out"),
          onClick: logout,
        },
      ]}
    />
  );
};
