/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { ValidateSessionTfaInput } from "graphql/types";

const VALIDATE_SESSION_TFA = gql`
  mutation ($input: ValidateSessionTfaInput!) {
    validateSessionTfa(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface ValidateSessionTfaMutation {
  validateSessionTfa: Payload | null;
}

export const useValidateSessionTfaMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.validatedSessionTfa",
    "Signed in successfully"
  );
  // Values for extraction
  // t("common:success.validatedSessionTfa", "Signed in successfully")

  const [mutation, { data, loading }] = useMutation<
    ValidateSessionTfaMutation,
    { input: ValidateSessionTfaInput }
  >(VALIDATE_SESSION_TFA);

  const validateSessionTfa = (
    input: ValidateSessionTfaInput,
    onSuccess?: (sessionToken: string) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.validateSessionTfa?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(input.sessionToken),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    validateSessionTfa,
    isLoading: loading,
    errors: data?.validateSessionTfa?.errors ?? [],
  };
};
