/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { ResendTfaPhoneNumberConfirmationInput } from "graphql/types";

const RESEND_TFA_PHONE_NUMBER_CONFIRMATION = gql`
  mutation ($input: ResendTfaPhoneNumberConfirmationInput!) {
    resendTfaPhoneNumberConfirmation(input: $input) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface ResendTfaPhoneNumberConfirmationMutation {
  resendTfaPhoneNumberConfirmation: Payload | null;
}

export const useResendTfaPhoneNumberConfirmationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentPhoneNumberConfirmation",
    "Two-factor code was sent successfully"
  );
  // Values for extraction
  // t("common:success.sentPhoneNumberConfirmation", "Two-factor code was sent successfully")

  const [mutation, { data, loading }] =
    useMutation<ResendTfaPhoneNumberConfirmationMutation>(
      RESEND_TFA_PHONE_NUMBER_CONFIRMATION
    );

  const resendTfaPhoneNumberConfirmation = (
    input: ResendTfaPhoneNumberConfirmationInput,
    onSuccess?: (account: AccountFragment) => void,
    onDataError?: (errors: ErrorFragment[]) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.resendTfaPhoneNumberConfirmation?.errors ?? [];
        const account = data?.resendTfaPhoneNumberConfirmation?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });

        if (onDataError && dataErrors.length) onDataError(dataErrors);
      })
      .catch((_error: ApolloError) => null);

  return {
    resendTfaPhoneNumberConfirmation,
    isLoading: loading,
    account: data?.resendTfaPhoneNumberConfirmation?.account,
    errors: data?.resendTfaPhoneNumberConfirmation?.errors ?? [],
  };
};
