import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Icon, Input } from "components/common/basic";
import { BrregSearch } from "components/common/composite";
import {
  AccountFragment,
  ListCompanyFragment,
  PaymentMethodFragment,
} from "graphql/fragments";
import { useCreatePaymentMethodMutation } from "graphql/mutations";
import { CountryCodeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { IntlContext } from "providers/i18n";
import { useTranslation } from "translations";
import { gTag, getCountryNameFromCode } from "utils";
import { tw } from "utils/tw";

interface FormValues {
  organization: ListCompanyFragment;
  contactName: string;
  orderReference: string;
  line1: string;
  city: string;
  county: string;
  postalCode: string;
  invoiceEmail: string;
}

interface Props {
  account: AccountFragment;
  isFlat: boolean;
  submitLabel?: string;
  onSuccess?: (paymentMethod: PaymentMethodFragment) => void;
}

export default ({
  account,
  isFlat,
  submitLabel,
  onSuccess,
}: Props): JSX.Element => {
  const { createPaymentMethod, isLoading } = useCreatePaymentMethodMutation();

  const { t } = useTranslation("common");
  const { closeOnePopup } = useContext(PopupContext);
  const { session } = useContext(AuthContext);
  const { currentLocale } = useContext(IntlContext);
  const [_searchParams, setSearchParams] = useSearchParams();

  const validationSchema = Yup.object<FormValues>().shape({
    organization: Yup.mixed<ListCompanyFragment>().required(
      t(
        "checkoutForm_invoice.organization.error",
        "Please find your organization"
      )
    ),
    contactName: Yup.string().required(
      t(
        "checkoutForm_invoice.contactName.error",
        "A contact person is required"
      )
    ),
    orderReference: Yup.string().required(
      t(
        "checkoutForm_invoice.orderReference.error",
        "An order reference is required"
      )
    ),
    line1: Yup.string().required(
      t("checkoutForm_invoice.line1.error", "An address is required")
    ),
    city: Yup.string().required(
      t("checkoutForm_invoice.city.error", "A city is required")
    ),
    county: Yup.string().required(
      t("checkoutForm_invoice.county.error", "A county is required")
    ),
    postalCode: Yup.string().required(
      t("checkoutForm_invoice.postalCode.error", "A post code is required")
    ),
    invoiceEmail: Yup.string()
      .email(
        t("checkoutForm_invoice.email.error.format", "Incorrect email format")
      )
      .required(
        t("checkoutForm_invoice.email.error.required", "An email is required")
      ),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      organization: {
        name: account.organizationName,
        orgNumber: account.organizationNumber,
      } as ListCompanyFragment,
      line1: account.address?.line1 ?? "",
      city: account.address?.city ?? "",
      county: account.address?.county ?? "",
      postalCode: account.address?.postalCode ?? "",
    },
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);
  const organization = watch("organization");

  const onInvoiceSubmit = async (values: FormValues) => {
    const attributes = {
      address: {
        line1: values.line1,
        city: values.city,
        county: values.county,
        postalCode: values.postalCode,
        country: CountryCodeEnum.No,
      },
      contactName: values.contactName,
      email: values.invoiceEmail,
      orderReference: values.orderReference,
      organizationNumber: values.organization.orgNumber,
    };

    createPaymentMethod(attributes, (paymentMethod) => {
      setSearchParams(
        new URLSearchParams([["payment_method_setup", "succeeded"]])
      );

      if (session?.account.registrationTier)
        gTag("conversion_event_subscribe_paid", {
          item_id: session.account.registrationTier.id,
          item_name: session.account.registrationTier.type,
          currency: session.account.registrationTier.currency,
          value: session.account.registrationTier.amount,
        });

      if (onSuccess) onSuccess(paymentMethod);
      else closeOnePopup();
    });
  };

  const cardStyles = tw("w-full", "bg-white", "space-y-6", {
    [tw("py-6", "px-4", "shadow-md", "rounded-lg")]: !isFlat,
  });

  return (
    <form onSubmit={handleSubmit(onInvoiceSubmit)} className={tw("space-y-6")}>
      <div className={cardStyles}>
        <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon icon="HiDocumentText" isFilled />

          <h2>{t("checkoutForm_invoice.heading", "Billing information")}</h2>
        </div>

        <Controller
          control={control}
          name="organization"
          render={({ field }) => (
            <div className={tw("w-full")}>
              <BrregSearch
                label={t(
                  `checkoutForm_invoice.organization.label`,
                  "Select your organisation"
                )}
                placeholder={
                  organization
                    ? organization.name
                    : t(
                        "checkoutForm_invoice.placeholder",
                        "Search for your organisation name or type in org.number"
                      )
                }
                onSelect={field.onChange}
                errorMessage={errors.organization?.message}
              />
            </div>
          )}
        />

        <div className={tw("grid", "grid-cols-1", "gap-6", "md:grid-cols-2")}>
          <Input
            id="contactName"
            {...register("contactName")}
            label={t(
              "checkoutForm_invoice.contactName.label",
              "Contact person name"
            )}
            errorMessage={errors.contactName?.message}
            fullWidth
          />

          <Input
            id="orderReference"
            {...register("orderReference")}
            label={t(
              "checkoutForm_invoice.orderReference.label",
              "Order reference"
            )}
            errorMessage={errors.orderReference?.message}
            fullWidth
          />
        </div>

        <Input
          id="invoiceEmail"
          {...register("invoiceEmail")}
          label={t("checkoutForm_invoice.invoiceEmail.label", "Invoice email")}
          errorMessage={errors.invoiceEmail?.message}
          fullWidth
        />
      </div>

      <div className={cardStyles}>
        <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon icon="HiLocationMarker" isFilled />

          <h2>
            {t(
              "checkoutForm_invoice.billingAddress.heading",
              "Billing address"
            )}
          </h2>
        </div>

        <Input
          id="line1"
          {...register("line1")}
          label={t("checkoutForm_invoice.line1.label", "Address")}
          errorMessage={errors.line1?.message}
          fullWidth
        />

        <div className={tw("grid", "grid-cols-1", "gap-6", "md:grid-cols-2")}>
          <Input
            id="city"
            {...register("city")}
            label={t("checkoutForm_invoice.city.label", "City")}
            errorMessage={errors.city?.message}
            fullWidth
          />

          <Input
            id="county"
            {...register("county")}
            label={t("checkoutForm_invoice.county.label", "County")}
            errorMessage={errors.county?.message}
            fullWidth
          />
        </div>

        <div className={tw("grid", "grid-cols-1", "gap-6", "md:grid-cols-4")}>
          <Input
            id="postalCode"
            {...register("postalCode")}
            label={t("checkoutForm_invoice.postalCode.label", "Post code")}
            errorMessage={errors.postalCode?.message}
            fullWidth
          />
        </div>

        <div className={tw("grid", "grid-cols-1", "gap-6", "md:grid-cols-2")}>
          <Input
            id="country"
            name="country"
            label={t("checkoutForm_invoice.country.label", "Country")}
            value={getCountryNameFromCode("NO", currentLocale)}
            disabled
          />
        </div>
      </div>

      <Button id="checkout-submit_form" type="submit" disabled={isLoading}>
        {submitLabel ?? t("checkoutForm_invoice.submit", "Subscribe")}
      </Button>
    </form>
  );
};
